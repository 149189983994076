.top-menu {
    display: flex;

    &-wrapper {
        display: none;

        @include media-breakpoint-up(md) {
            @include flex-center;
            padding-right: 0;
            padding-left: 50px;
            justify-content: flex-start;
        }
    }

    li {
        position: relative;
        text-transform: uppercase;
        margin-right: 20px;

        &:hover,
        &.active {
            >a {
                color: $blue;
                text-decoration: none;
            }
        }

        &.parent {
            margin-right: 0;
            padding-right: 20px;
            transition: color 0.3s linear;

            a {
                display: flex;
                align-items: center;
            }
        }

        a {
            color: $primary;
            font-weight: 600;
        }

        .submenu {
            display: none;
            width: 200px;
            position: absolute;
            left: 0;
            z-index: 1;
            padding-top: 20px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.3);
                background-color: #ffffff;
            }

            li {
                margin-right: 0;
                text-transform: none;
                transition: background-color .15s ease-in-out;

                &:hover {
                    background-color: #cdd6e9;

                    a {
                        color: $primary;
                    }
                }
            }

            a {
                position: relative;
                display: block;
                padding: 9px 10px 9px 14px;
                line-height: 1.4;
                font-weight: 400;
            }
        }
    }
}
