// base
// body {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     min-height: 100vh;
//     background-color: #fff;
//     font-family: $font-family-sans-serif;
//     font-size: 0.875rem;
//     color: $primary;
//     overflow-x: hidden;

//     &.ovhidden {
//         overflow: hidden;
//     }
// }

// .body-inner {
//     flex: 1 0 100%;
// }

// .container {
//     height: auto;
//     padding: 0 15px;
// }

// img {
//     width: 100%;
//     height: auto;
// }

// ul {
//     margin: 0;
//     padding: 0;
//     list-style-type: none;
// }

// [v-cloak] {
//     display: none;
// }

// svg {
//     transition: fill 0.3s linear;
// }

// .product-advantage:nth-child(odd) {
//     .description {
//         transition: translateX(-100%) !important;
//     }
// }

// .bg-white {
//     position: relative;
//     background-color: #fff;
//     z-index: 5;
// }

// @keyframes opacity-item-icon {
//     from {
//         opacity: 0;
//     }

//     to {
//         opacity: 1;
//     }
// }

// @include media-breakpoint-up(sm) {
//     .container {
//         padding: 0 35px;
//     }
// }

// @include media-breakpoint-up(lg) {
//     body {
//         font-size: 1.125rem;
//     }
// }

// end base

// inline
// h1,
// .h1 {
//     font-size: 1.875rem;
// }

// h2,
// .h2 {
//     font-size: 1.25rem;
//     font-weight: 700;
//     text-transform: uppercase;
// }

// h3,
// .h3 {
//     font-size: 1.125rem;
//     font-weight: 700;
//     margin-bottom: 20px;
// }

// .section-info {
//     font-size: 16px;
//     line-height: 24px;
// }

// p {
//     font-size: 1rem;
//     line-height: 1.6;
// }

// a {
//     border-bottom: 1px solid transparent;
//     transition: border-bottom 0.2s linear,
//         text-decoration 0.2s linear;

//     &:hover {
//         text-decoration: none;
//     }

//     &:focus {
//         outline: 0;
//     }
// }

// .section-description a,
// .section-wysiwyg a {
//     color: $blue;
//     @include underline-blue;

//     &:hover {
//         border-bottom-color: transparent;
//         text-decoration: none;
//     }
// }

// a.link-blue {
//     color: $blue;
//     @include underline-blue;

//     &:hover {
//         border-bottom-color: transparent;
//         text-decoration: none;
//     }

//     &.on_u {
//         border-bottom-color: transparent;
//         text-decoration: none;

//         &:hover {
//             @include underline-blue;
//         }
//     }
// }

// a.link-white {
//     color: $white;
//     @include underline-white;
//     transition: border-bottom 0.2s linear;

//     &:hover {
//         border-bottom-color: transparent;
//         text-decoration: none;
//     }
// }

// a.link-gray {
//     color: $gray;
//     @include underline-gray;
//     transition: color 0.2s linear,
//         border-bottom 0.2s linear;

//     &:hover {
//         border-bottom: 1px solid transparent;
//         text-decoration: none;
//     }
// }

// a.link-dark {
//     color: $primary;
//     @include underline-dark;
//     transition: color 0.2s linear,
//         border-bottom 0.2s linear;

//     &:hover {
//         border-bottom: 1px solid transparent;
//         text-decoration: none;
//     }

//     &.on_u {
//         border-bottom-color: transparent;
//         text-decoration: none;

//         &:hover {
//             @include underline-dark;
//         }
//     }
// }

// a.no_u {
//     text-decoration: none;
//     border-bottom: 1px solid transparent;

//     &:hover {
//         text-decoration: none;
//         border-bottom: 1px solid transparent;
//     }
// }

// .gray {
//     color: $gray;
// }

// strong,
// .strong {
//     font-weight: 700;
// }

// .semibold {
//     font-weight: 600;
// }

// .light {
//     font-weight: 300;
// }

// .list,
// .project-item ul {
//     line-height: 1.3;
//     list-style-type: none;
//     margin-bottom: 15px;

//     li {
//         position: relative;
//         font-size: 0.875rem;
//         // letter-spacing: -0.25px;
//         padding-left: 20px;
//         margin-bottom: 3px;

//         &:before {
//             content: '';
//             position: absolute;
//             top: 8px;
//             left: 0;
//             display: block;
//             width: 5px;
//             height: 5px;
//             border-radius: 50%;
//             @include gradient;
//         }
//     }
// }


// .animate-width {
//     transition: width .3s ease-in-out;
// }

// @include media-breakpoint-up(sm) {

//     h1,
//     .h1 {
//         font-size: 2.75rem;
//     }

//     h2,
//     .h2 {
//         font-size: 1.625rem;
//     }

//     h3,
//     .h3 {
//         font-size: 1.25rem;
//     }
// }

// @include media-breakpoint-up(md) {

//     h2,
//     .h2 {
//         font-size: 2.125rem;
//     }

//     .list,
//     .project-item ul {
//         margin-bottom: 20px;
//         line-height: 1.5;

//         li {
//             font-size: 1rem;
//             padding-left: 35px;
//             margin-bottom: 0;
//         }
//     }
// }

// @include media-breakpoint-up(lg) {

//     h1,
//     .h1 {
//         font-size: 3.125rem;
//     }

//     h2,
//     .h2 {
//         font-size: 2.125rem;
//     }

//     h3,
//     .h3 {
//         font-size: 1.5rem;
//     }
// }

// end inline

// header
.header {
    flex-grow: 0;
    background-color: #fff;
    transition: box-shadow .15s linear;
    box-shadow: 0 0 3px #ccc;
}

.header-inner {
    height: 95px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.logo-wrapper {
    max-width: 196px;
    padding: 0;
    margin-left: -15px;

    img {
        width: 100%;
        min-width: 165px;
        height: 44px;
    }
}

// Lang
.lang-wrapper {
    display: none;
}

.lang-menu {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        font-weight: 400;

        svg+span {
            margin-left: 15px;
        }

        &.parent {
            position: relative;

            .lang {
                color: $link;
                @include underline-blue;
                margin-right: 5px;
            }

            svg:first-child {
                display: none;
            }
        }
    }

    a,
    button {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $primary;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
    }

    .lang {
        position: relative;
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.1;
        transition: color 0.2s linear;
    }
}

.lang-menu .submenu {
    display: none;
    width: 105px;
    position: absolute;
    right: 0;
    z-index: 1;
    padding-top: 20px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.3);
        background-color: #ffffff;
    }

    li {
        transition: background-color .15s ease-in-out;

        &:hover {
            background-color: #cdd6e9;

            .lang {
                color: $primary;
                border-bottom: none;
            }
        }
    }

    a {
        position: relative;
        width: 92px;
        display: flex;
        padding: 12px 0 12px 12px;
        line-height: 1;

        svg:first-child {
            display: block;
        }

        .lang {
            color: $primary;
            border-bottom: none;
        }
    }
}

// Hamburger
// .hamburger {
//     outline: none !important;
// }

// .hamburger-wrapper {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     flex-shrink: 0;
//     width: 80px;
//     height: 100%;
//     transform: translateX(-20px);
//     padding-left: 35px;
//     padding-right: 0;
//     border: 0;
//     outline: none;

//     &.is-active {
//         background-color: #30519a;

//         .hamburger.is-active .hamburger-inner,
//         .hamburger.is-active .hamburger-inner:before,
//         .hamburger.is-active .hamburger-inner:after {
//             background-color: #ffffff;
//         }

//         .hamburger.is-active .hamburger-inner:before {
//             top: -5px;
//             right: 2px;
//         }

//         .hamburger.is-active .hamburger-inner:after {
//             bottom: -5px;
//             right: 2px;
//         }
//     }
// }

// .hamburger-inner {
//     margin-top: 0;
// }

// Search
.search-wrapper {
    max-width: 72px;
    padding-left: 13px;
}

.search {
    float: right;
    position: relative;
    width: 42px;
    height: 42px;

    &.closed {
        overflow: hidden;
    }

    .find {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 42px;
        height: 42px;
        padding: 0;
        border: none;
        border-radius: 50%;
        @include gradient;
        outline: none;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(../../images/svg/loupe.svg);
            background-repeat: no-repeat;
            background-size: 17px;
            background-position: center;
            transform: rotate(0deg) scale(1);
            opacity: 1;
            visibility: visible;
            transition: .3s transform;
        }

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(../../images/svg/close.svg);
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: center;
            border-radius: 50%;
            opacity: 0;
            visibility: hidden;
            transform: rotate(-90deg) scale(.4);
            transition: .3s transform;
        }
    }

    input {
        position: absolute;
        right: 0;
        z-index: 1;
        width: 42px;
        height: 100%;
        padding-left: 18px;
        border: 1px solid $blue;
        border-radius: 42px;
        outline: none !important;

        &:focus {
            outline: none !important;
        }

        &::placeholder {
            font-size: 0.875rem;
            color: #3d4752;
            opacity: 1;
        }
    }

    .loupe {
        position: absolute;
        top: 0;
        right: 42px;
        z-index: 2;
        width: 42px;
        height: 42px;
        background-image: url(../../images/svg/loupe-blue.svg);
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        cursor: pointer;
        animation-duration: 300ms;
    }

    &.opened {
        .find {
            &:before {
                opacity: 0;
                visibility: hidden;
                transform: rotate(90deg) scale(.4);
            }

            &:after {
                transform: rotate(0deg) scale(1);
                opacity: 1;
                visibility: visible;
            }
        }

        .loupe {
            z-index: 5;
            opacity: 1;
        }
    }

    &-result-list {
        .news-single__data {
            position: relative;
        }
    }
}

@include media-breakpoint-up(sm) {
    .search-wrapper {
        margin-left: auto;
    }
}

@include media-breakpoint-up(md) {
    .lang-wrapper {
        display: block;
        max-width: 77px;
        margin-left: auto;
        padding: 0;
    }
}

@include media-breakpoint-up(lg) {
    body {
        font-size: 1.125rem;
    }

    .top-menu {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(xl) {
    // .hamburger-wrapper {
    //     display: none;
    // }

    .logo-wrapper {
        margin-left: 0;
    }
}


// Mobile menu
.mobile-nav-wrap {
    width: 100%;
    display: none;
    overflow: hidden;
    position: fixed;
    top: 88px;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    @include media-breakpoint-up(sm) {
        top: 95px;
        width: 360px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.mobile-nav {
    position: absolute;
    width: 100%;
    min-height: 1vh;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: auto;
    background-color: #30519a;
}

.mobile-main {
    font-size: 1rem;
    font-weight: 400;

    li {
        background-color: #30519a;
        transition: background-color .3s linear;
    }

    >li.active,
    >li:hover {
        background-color: #5974ae;
    }

    li.parent {
        overflow: hidden;
    }

    li.parent>a {
        position: relative;
    }

    li.parent>a:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 36px;
        display: block;
        width: 10px;
        height: 5px;
        margin-top: -3px;
        background-image: url(../../images/svg/arrow-down-white.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    a {
        display: flex;
        align-items: center;
        padding: 15px 60px 15px 35px;
        color: #ffffff;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
        }

        svg+span {
            margin-left: 20px;
        }
    }
}

.mobile-main .submenu {
    display: none;

    &.open {
        display: block;
    }

    li a span {
        transition: border .3s linear;
        border-bottom: 1px solid transparent;
    }

    li.active a span,
    li:hover a span {
        border-bottom-color: #ffffff;
    }

    a {
        position: relative;
        padding-left: 70px;
        text-transform: none;
    }

    a:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 41px;
        width: 6px;
        height: 12px;
        margin-top: -6px;
        background-image: url(../../images/svg/arrow-right-white.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
}

.lang-menu-mobile {
    li a span {
        color: #ffffff;
    }
}

.lang-menu-mobile .submenu {

    li.active a>span,
    li:hover a>span {
        border-bottom-color: transparent;
    }

    a {
        padding-left: 35px;
    }

    a:before {
        display: none;
    }

    span.lang {
        text-transform: uppercase;
    }
}

.breadcrumbs {
    display: none;
    margin-bottom: 4px;

    @include media-breakpoint-up(md) {
        margin-bottom: 7px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 9px;
    }

    .breadcrumb {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            font-size: 0.875rem;

            @include media-breakpoint-up(md) {
                font-size: 1rem;
            }

            a {
                color: $primary;
                font-weight: 300;
                text-decoration: none;
                border-bottom: 1px solid rgba($primary, .3);
                padding: 0;
                margin: 0 3px;

                &:hover {
                    border-bottom-color: transparent;
                    text-decoration: none;
                }
            }

            .delimiter {
                font-weight: 300;

                @include media-breakpoint-up(md) {
                    font-size: 1rem;
                }
            }
        }

        li.active {
            color: $primary;
            text-decoration: none;
        }
    }
}

@include media-breakpoint-up(sm) {
    .breadcrumbs {
        display: block;
    }
}

@include media-breakpoint-up(md) {
    .breadcrumbs .breadcrumb li {
        display: inline;
        font-size: 1rem;

        .delimiter {
            font-size: 1rem;
        }
    }
}

// Section
.section {
    padding: 15px 0;
}

.section-title {
    margin-bottom: 20px;
}

.section-content {
    margin-bottom: 20px;

    &.section-content-not-mb {
        margin-bottom: 0;
    }
}

.section-action {
    .btn {
        width: 100%;
        max-width: 340px;
    }

    &--group {
        .btn {
            padding-left: 10px;
            padding-right: 10px;
            margin: 0 5px 10px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section {
        padding-bottom: 35px;
    }

    .section-title {
        text-align: center;
    }

    .section-action {
        .btn {
            width: auto;
        }

        &--group .btn {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}

@include media-breakpoint-up(md) {
    .section {
        padding-bottom: 45px;
    }

    .section-title {
        margin-bottom: 40px;
    }

    .section-content {
        margin-bottom: 35px;
    }
}

@include media-breakpoint-up(lg) {
    .section-content {
        margin-bottom: 40px;
    }

    // .section-action {
    //     &--group .btn {
    //         padding: 10px 48px;
    //     }
    // }
}

// Slider
.section-slider {
    padding: 0;
}

.slider-main {
    position: relative;
    width: 100%;
    height: 225px;

    .slick-slide {
        height: 100%;
    }
}

.slide-main {
    display: flex;
    width: 100%;
    height: 225px;
    background-color: #dbd5e3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;

    img {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: -1;
    }

    &:first-child {
        position: relative;
        z-index: 2;
    }

    &:not(:first-child) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    &:after {
        content: '';
        width: 100%;
        height: 75px;
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        @include gradient-opacity;
        z-index: 1;
    }

    .slick-slide {
        transition-delay: 1s !important;
    }
}

.slider-main.slick-initialized {
    .slide-main {
        position: relative;
    }
}

.slide-inner {
    height: 225px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slide-left {
        display: flex;
        padding: 15px 0;
        overflow: hidden;
    }

    .slide-right {
        height: 75px;
        text-align: center;
        padding: 20px 0;
        z-index: 10;

        .btn {
            width: 100%;
            max-width: 320px;
        }

        .animated.fadeInUp {
            animation-duration: 1.5s;
            animation-delay: 0.3s;
        }
    }
}

.title-slide {
    opacity: 0;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 300;
    flex: 0 0 225px;

    &.active {
        opacity: 1;
    }

    &.animated {
        animation-duration: 1.5s;
        animation-delay: 0.3s;

        &.active {
            animation-delay: 0s;
        }
    }

    .big {
        display: inline-block;
        font-size: 1.875rem;
    }
}

.subtitle-slide {
    opacity: 0;

    &.active {
        opacity: 1;
    }

    &.animated {
        animation-duration: 1.5s;
        animation-delay: 0.3s;

        &.active {
            animation-delay: 0s;
        }
    }
}

@include media-breakpoint-up(sm) {

    .slider-main,
    .slide-main,
    .slide-inner {
        height: 370px;
    }

    .slide-main {
        &:after {
            width: 58%;
            height: 100%;
            top: 0;
            left: auto;
            right: -10%;
            transform: skew(-15deg);
        }
    }

    .slide-inner {
        flex-direction: row;
        padding: 45px 0 55px;

        .slide-left {
            max-width: 52%;
            padding: 0;

            .line-break {
                display: block;
            }
        }

        .slide-right {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0;
            padding-right: 45px;
            text-align: right;

            .info-slide {
                font-weight: 600;
                font-size: 2.125rem;
                line-height: 1.3;
                color: $white;
                text-align: right;
                margin-bottom: 25px;
            }

            .btn {
                width: auto;
            }
        }
    }

    .title-slide {
        flex: 0 0 370px;
        font-size: 2.25rem;

        .big {
            font-size: 2.75rem;
        }
    }
}

@include media-breakpoint-up(md) {

    .slider-main,
    .slide-main,
    .slide-inner {
        height: 440px;
    }

    .slide-main {
        &:after {
            width: 50%;
        }
    }

    .slide-inner {
        .slide-left {
            .line-break {
                display: inline-block;
            }
        }

        .slide-right .info-slide {
            font-size: 2.5rem;
            font-weight: 400;
        }
    }

    .title-slide {
        font-size: 3.125rem;
        flex: 0 0 480px;

        .big {
            font-size: 3.4375rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .slide-inner {
        .slide-left {
            max-width: 62%;

            .title-slide {
                flex: 0 0 700px;

                .str-small {
                    padding-right: 200px;
                }
            }
        }
    }
}

// Section-about
.about-content {
    &-row {
        margin-bottom: 0;

        .block-about .text {
            margin-bottom: 0;
        }
    }
}

.experience {
    line-height: 1;
    padding-bottom: 12px;
}

.about-experience {
    display: flex;
    flex-direction: column;
}

.years {
    position: relative;
    display: inline;
    font-family: 'Akrobat-Black';
    font-size: 3.125rem;
    line-height: 1;
    letter-spacing: -0.1rem;
    @include gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 20px;

    &:before {
        content: '';
        display: block;
        width: 6px;
        height: 42px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: #dfdfe5;
    }
}

.content-about {
    display: flex;
    flex-direction: column;
}

.years-text {
    font-size: 1.25rem;
    font-weight: 300;
}

.text {
    font-size: 0.875rem;
    line-height: 1.3;
    margin-bottom: 15px;
    p {
        font-size: 0.875rem;
        line-height: 1.3;
        margin-bottom: 0;
    }
}

.progress-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    &.progress-list-two-elems {
        .progress-item {
            justify-content: flex-start;
            border-bottom: none;
        }
    }
    &-mb {
        margin-bottom: 15px;
    }
}

.progress-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px 5px 10px;

    &:nth-child(2n+1) {
        border-right: 1px solid #e8e8ec;
    }

    &:nth-child(-n+2) {
        border-bottom: 1px solid #e8e8ec;
    }
}

.progress-title {
    font-size: 2.875rem;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 0.8;
    color: $blue;
    margin-bottom: 10px;
}

.progress-title-sub {
    font-size: 1.625rem;
}

.progress-text {
    font-size: 0.875rem;
    line-height: 1.2;
}


@include media-breakpoint-up(sm) {
    .section-about {
        padding-top: 25px;
        padding-bottom: 25px;

        &-padding {
            padding-top: 35px;
            padding-bottom: 10px;
        }

        .block-about {
            margin-bottom: 15px;
        }

        .text {
            line-height: 1.4;
        }
    }

    .about-content {
        &-row {
            .progress-list {
                width: 314px;
                float: right;
            }
        }
    }

    #particles-js {
        position: absolute;
        min-width: 270px;
        min-height: 220px;
        top: 0;
        left: 0;

        .particles-js-canvas-el {
            min-width: 270px !important;
            min-height: 220px !important;
        }
    }

    .years {
        flex: 0 0 282px;
        font-size: 18.75rem;
        line-height: 0.8;
        padding-left: 0;
        letter-spacing: 0;
        margin-right: 25px;

        .gradient-num {
            @include gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity: 0;
            letter-spacing: 20px;
            margin-left: 30px;
        }

        &:before {
            display: none;
        }
    }

    .years-text {
        position: relative;
        display: inline-block;
        max-width: 260px;
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 300;
        padding-left: 25px;
        margin-bottom: 25px;

        &:before {
            content: '';
            display: block;
            width: 6px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 3px;
            background-color: #dfdfe5;
        }
    }

    .progress-list {
        &-two-elems {
            .progress-item {
                flex-basis: 50%;
                max-width: 50%;
                &:nth-child(2) {
                    border-right: none;
                }
            }
        }
    }

    .progress-item {
        justify-content: flex-start;
        flex: 0 0 25%;
        min-height: 122px;
        padding-left: 8px;
        padding-right: 8px;

        &:nth-child(1) {
            order: 1;
        }

        &:nth-child(2) {
            order: 3;
        }

        &:nth-child(3) {
            order: 2;
        }

        &:nth-child(4) {
            order: 4;
        }

        &:nth-child(2n+1) {
            border-right: none;
        }

        &:nth-child(-n+2) {
            border-bottom: none;
        }

        &:nth-child(-n+3) {
            border-right: 2px solid #e8e8ec;
        }
    }

    .progress-title {
        font-size: 3.5rem;
        margin-bottom: 15px;
    }

    .progress-title-sub {
        font-size: 2.25rem;
    }

    .progress-text {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(md) {
    .section-about {
        padding-top: 35px;

        .section-content {
            margin-bottom: 50px;
        }

        &-padding {
            padding-bottom: 0;
        }
    }

    .about-content {
        &-row {
            .progress-list {
                width: 395px;
                margin-left: 40px;
            }
        }
    }

    #particles-js {
        min-width: 390px;
        min-height: 310px;

        .particles-js-canvas-el {
            min-width: 390px !important;
            min-height: 310px !important;
        }
    }

    .years {
        flex: 0 0 408px;
        font-size: 27.125rem;
        margin-right: 15px;
    }

    .years-text {
        max-width: 200px;
        margin-bottom: 40px;
    }

    .text {
        font-size: 1rem;
        line-height: 1.5;
        p {
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 10px;
        }
    }

    .clients-block {
        margin-bottom: 45px;
    }

    .progress-list {
        margin-bottom: 45px;
        &-two-elems {
            .progress-item {
               padding-top: 25px;
            }
        }
    }

    .progress-item {
        min-height: 140px;
        max-height: 100%;
    }

    .progress-title {
        font-size: 4.375rem;
    }

    .progress-title-sub {
        font-size: 2.5rem;
    }

    .progress-text {
        font-size: 1.125rem;
    }
}

@include media-breakpoint-up(lg) {
    .section-about {
        .text {
            line-height: 1.5;
        }

        .btn {
            padding: 10px 35px;
        }

        &-padding {
            padding-top: 50px;
        }
    }

    .about-content {
        display: flex;
        flex-direction: row;

        &-row {
            flex-direction: row-reverse;
            justify-content: space-between;

            .progress-list {
                height: auto;
            }
        }
    }

    .section-about .section-content {
        margin-bottom: 10px;
    }

    .block-about {
        flex: 1 0 65%;
    }

    .years-text {
        margin-bottom: 20px;
    }

    .progress-list {
        min-width: 350px;
        height: 330px;
        flex: 0 0 30%;
        margin-left: 30px;
        justify-content: center;
        &-two-elems {
            min-width: 380px;
            margin-left: 0;

            .progress-item {
                height: auto;
            }
        }
    }

    .progress-item {
        min-width: 175px;
        height: 165px;
        flex: 0 0 50%;
        max-width: 50%;
        border-right: none;
        padding: 25px 15px 12px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            order: 0;
        }

        &:nth-child(-n+3) {
            border-right: none;
        }

        &:nth-child(odd) {
            border-right: 1px solid #e8e8ec;
        }

        &:nth-child(2) {
            border-right: none;
        }

        &:nth-child(-n+2) {
            border-bottom: 1px solid #e8e8ec;
        }
    }
}

@include media-breakpoint-up(xl) {
    .block-progress {
        min-width: 395px;
    }

    .about-experience {
        margin-right: 55px;
    }

    .about-content-row .progress-list-two-elems {
        min-width: 410px;
    }
}


// Products
.section-products {
    .section-title {
        margin-bottom: 0;
    }

    .section-content {
        margin-bottom: 30px;
    }

    &-indent {
        padding-bottom: 0;
        .section-content {
            margin-bottom: 0;
        }
    }
}

.products-list {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;

    &-client {
        .product {
            padding-top: 0;
            padding-bottom: 15px;
            border-bottom: none;

            &-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-icon {
                width: 60px;
                height: 60px;
                margin-top: 0;
                &.product-icon-in-title {
                    display: none;
                }
            }

            &-name {
                margin-bottom: 5px;
            }

            &:hover {
                color: $primary;
                &:after {
                    opacity: 0;
                }
            }
        }
    }
}

.product {
    position: relative;
    display: flex;
    padding: 20px 15px;
    border-bottom: 1px solid #e8e8ec;
    color: $primary;
    line-height: 1.3;
    background: $white;
    transition: all 0.2s linear,
        color 0.2s linear;

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include gradient;
        opacity: 0;
        transition: opacity 0.2s linear;
        z-index: 1;
    }

    &:hover {
        text-decoration: none;
        color: $white;
        background-position: 0 0;

        &:after {
            opacity: 1;
        }

        .product-icon-before {
            opacity: 0;
        }

        .product-icon-after {
            opacity: 1;
        }
    }
}

.product-icon {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    margin-right: 15px;
    margin-top: 10px;
    z-index: 3;

    .product-icon-before,
    .product-icon-after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s linear;
    }

    .product-icon-before {
        opacity: 1;
    }

    .product-icon-after {
        opacity: 0;
    }
}

.product-box {
    position: relative;
    z-index: 3;
}

.product-name {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.product-text {
    font-size: 0.875rem;
    padding-right: 5px;
}

.product-more {
    position: absolute;
    bottom: 30px;
    right: 15px;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: rgba($color: #fff, $alpha: 0.9);
    align-self: flex-end;
    z-index: 3;

    &:before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: rgba($color: #fff, $alpha: 0.9);
    }

    &:before {
        top: 10px;
    }

    &:after {
        bottom: 10px;
    }
}

@include media-breakpoint-up(sm) {
    .section-products {
        .section-title {
            margin-right: 0;
        }

        .h2 {
            font-size: 1.625rem;
        }

        &-indent {
            .section-content {
                padding-top: 0;
            }
        }
    }

    .products-posttitle {
        text-align: center;
    }

    .products-content {
        padding-top: 35px;
    }

    .products-list {
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        &-client {
            .product {
                flex-direction: row;
                align-items: flex-start;
                flex-basis: 50%;
                max-width: 50%;
                padding-top: 20px;
                padding-left: 0;

                &:nth-child(3n+2),
                &:nth-child(3n+1) {
                    border-right: none;
                }

                &-icon {
                    width: 70px;
                    height: 70px;
                    margin: 0 15px 0 0;
                }

                &-box {
                    height: 100%;
                    text-align: left;
                }

                &-name {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .product {
        flex-direction: column;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        align-items: center;
        padding: 15px 7px 40px;

        &:nth-last-child(-n+3) {
            border-bottom: none;
        }

        &:nth-child(3n+2),
        &:nth-child(3n+1) {
            border-right: 1px solid #e8e8ec;
        }
    }

    .product-icon {
        width: 70px;
        height: 70px;
        margin: 0 0 20px 0;
    }

    .product-box {
        text-align: center;

        .product-name {
            font-size: 1.25rem;
        }

        .product-text {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-products {
        padding-top: 75px;
        padding-bottom: 35px;

        .h2 {
            font-size: 2.125rem;
        }

        &-indent {
            padding-top: 0;
            padding-bottom: 0;
            &-top {
                padding-top: 35px;
            }
            .section-title {
                margin-bottom: 15px;
            }
        }
    }

    .products-content {
        padding-top: 55px;
        padding-bottom: 5px;
    }

    .product {
        padding: 35px 18px 35px;

        .product-icon {
            width: 75px;
            height: 75px;
        }
    }

    .product-box {
        .product-name {
            font-size: 1.5rem;
            margin-bottom: 15px;
        }

        .product-text {
            font-size: 1rem;
            line-height: 1.5;
        }
    }

    .products-list {
        &-client {
            .product {
                flex-direction: column;
                align-items: flex-start;
                flex-basis: 33.33%;
                max-width: 33.33%;
                padding-top: 15px;
                padding-left: 0;

                &-box {
                    height: auto;
                    justify-content: flex-start;
                }

                &-icon {
                    width: 75px;
                    height: 75px;
                    margin: 0 0 15px;
                }

                &-name {
                    height: 62px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .section-products {
        padding-top: 20px;
        &-indent-top {
            padding-top: 45px;
        }
    }

    .products-content {
        padding-bottom: 10px;
    }

    .products-posttitle {
        max-width: 615px;
        margin: 0 auto 25px;
    }
}

@include media-breakpoint-up(xl) {
    .section-products {
        padding-bottom: 0;

        .products-content {
            padding-bottom: 50px;
            margin-bottom: 0;
        }

        .section-action {
            padding-bottom: 45px;
        }
    }

    .products {
        padding-left: 28px;
        padding-right: 28px;
    }

    .products-list {
        &-client {
            .product {
                margin-bottom: 25px;
                padding-right: 35px;
                &-icon {
                    display: none;
                    &.product-icon-in-title {
                        display: inline-block;
                        margin-bottom: 0;
                        margin-right: 25px;
                        width: 75px;
                        height: 75px;
                    }
                }

                &-name {
                    display: flex;
                    align-items: center;
                    height: 85px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}

// section-connection
.section-connection {
    padding-top: 0;
}
// end section-connection

// Section-clients

.section-clients {
    padding-top: 20px;
}

.clients-block {
    .progress-list {
        margin-bottom: 15px;
    }
}

.section-quote {
    &__content {
        background-color: #f6f6f6;
        margin-bottom: 30px;
    }

    &.section-quote-not-padding {
        padding: 0;
    }

    .quote-block {
        margin-bottom: 15px;
    }

    .quote-author {
        position: relative;
        text-align: center;
        margin-bottom: 10px;
        z-index: 2;
    }
}

.quote-content {
    display: flex;
    flex-direction: column-reverse;
    padding: 40px 0 10px;
}

.quote-block {
    line-height: 1.3;
    padding: 15px 0;
}

.quote {
    position: relative;
    display: block;
    z-index: 2;
    margin-bottom: 10px;
}

.quote-author {
    font-weight: 700;

    &--post {
        font-weight: 400;
        font-size: 0.875rem;
        margin-top: 3px;
    }
}

.quote-author-photo {
    width: 145px;
    height: 145px;
    background-color: #000;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    &-bank {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        img {
            width: 125px;
            max-height: 70px;
            object-fit: contain;
            margin: 0 10px;
        }
    }
}

.quote-author-name {
    margin: 15px auto 0;
}

.quote-img-client {
    max-width: 200px;
    margin-top: 15px;

    img {
        max-height: 50px;
        object-fit: contain;
        object-position: left center;
    }
}

.quote-link {
    display: inline-block;
    font-size: 1rem;
    margin-top: 15px;
}

.quote-more {
    font-size: 0.875rem;
}

@include media-breakpoint-up(sm) {
    .section-clients {
        padding-top: 10px;
        padding-bottom: 0;

        .h2 {
            font-size: 1.625rem;
        }
    }

    .section-quote {
        .quote-author {
            margin-bottom: 0;
        }
    }

    .quote-content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .quote-block {
        flex: 1 0 50%;
        max-width: 100%;
        line-height: 1.45;
        margin-right: 20px;
    }

    .quote-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .quote-author {
            padding-right: 20px;
        }
    }

    .quote-autor {
        margin-bottom: 0;
    }

    .quote-author-photo {
        width: 185px;
        height: 185px;
    }

    .quote-img-client img {
        margin-top: 0;
    }

    .clients-block {
        .progress-list {
            margin-bottom: 35px;
        }

        .progress-item {
            min-height: 122px;
            max-height: 100%;
            padding-left: 10px;
            padding-right: 10px;

            &:nth-child(2) {
                order: 3;
            }

            &:nth-child(3) {
                order: 4;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-clients {
        .section-title {
            margin-bottom: 30px;
        }

        .h2 {
            font-size: 2.125rem;
        }
    }

    .section-quote {
        .quote-block {
            margin-bottom: 0;
            margin-right: 30px;
            padding: 25px 0;
        }

        .quote-author {
            flex: 0 0 300px;
            max-width: 300px;

            &--post {
                font-size: 1rem;
            }
        }
    }

    .quote-content {
        padding: 45px 0 20px;
    }

    .quote {
        font-size: 1rem;
        // margin-bottom: 0;
    }

    .quote-author {
        font-size: 1rem;
    }

    .quote-author-photo {
        width: 180px;
        height: 180px;

        &-bank {
            img {
                width: 150px;
                max-height: 85px;
                margin: 0 15px;
            }
        }
    }

    .quote-bottom {
        margin-top: 20px;
    }
}

@include media-breakpoint-up(lg) {
    .section-clients {
        padding-top: 25px;
    }

    .clients-block {
        display: flex;
        margin-bottom: 0;

        .progress-list {
            min-width: 375px;
            margin: 7px 40px 0 0;
        }

        .progress-text {
            font-size: 1rem;
            padding: 0;
        }

        .progress-item {
            justify-content: center;

            &:nth-child(-n+3) {
                border-right: none;
            }

            &:nth-child(1) {
                border-right: 1px solid #e8e8ec;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
                border-right: none;
            }

            &:nth-child(4) {
                order: 3;
                border-right: 1px solid #e8e8ec;
            }
        }
    }

    .quote-block {
        .h2 {
            margin-bottom: 50px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-quote {
        .quote-block {
            margin-right: 60px;
            flex-basis: 75%;
            max-width: 75%;
            margin-right: 15px;
        }

        .quote-content {
            padding-bottom: 30px;
        }
    }
}

// Slider-clients

.section-clients {
    padding-bottom: 5px;

    .section-header {
        span:after {
            background-color: #424852;
        }
    }
}

.client-list {
    height: 90px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0 20px;
}

.client {
    width: 138px;
    height: 90px;
    margin-left: 7px;
    margin-right: 7px;

    &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 12px;

        img {
            max-width: 100%;
            max-height: 100%;
            filter: saturate(0);
            object-fit: contain;
            transition: all .15s ease-in-out;
        }

        &:hover {
            img {
                filter: saturate(1);
            }
        }

        &:focus {
            outline: none;
        }
    }
}

@include media-breakpoint-up(sm) {
    .client-list {
        padding: 0 40px;
    }

    .client {
        width: 161px;
    }
}

@include media-breakpoint-up(md) {
    .section-clients {
        padding-bottom: 15px;

        .section-content {
            margin-bottom: 20px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .client-list {
        margin-top: 20px;
    }
}

// Section-info

.section-info {
    padding-top: 0;
    padding-bottom: 0;
}

.info-content {
    background-color: #ebebeb;
    margin-bottom: 0;
    overflow: hidden;
}

.info-container {
    display: flex;
    flex-direction: column;
}

.news-block {
    padding-top: 20px;
    padding-bottom: 50px;
    line-height: 1.3;
}

.news-list {
    margin-bottom: 20px;
}

.new {
    max-height: 175px;
    border-bottom: 1px solid #c9cbcc;
    padding: 15px 0;

    .new-head {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 0.75rem;
        max-height: 56px;
        overflow: hidden;
    }

    .new-text-block {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom: 1px;
    }

    a.new-text {
        display: inline;
        color: $primary;

        &:hover {
            color: $primary;
        }
    }

    .new-footer {
        font-size: 0.75rem;
        margin-top: 10px;
        cursor: default;
    }
}

.new-label,
.search-block-label {
    position: relative;
    height: 27px;
    color: $white;
    padding: 0 10px;
    margin: 0 10px 5px 5px;
    font-size: 0.875rem;
    line-height: 27px;
    vertical-align: middle;
    cursor: default;
    z-index: 2;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 27px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $blue;
        transform: skewX(-15deg);
        z-index: -1;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

.new-tag,
.search-block-tag {
    color: $primary;
    font-size: 0.875rem;
    margin-bottom: 5px;
}

.news-btn {
    text-align: center;

    .btn {
        width: 100%;
        max-width: 320px;
    }
}

.inform-block {
    display: flex;
    flex-direction: column;
}

.inform-item {
    position: relative;
    height: 165px;
    margin: 0 -35px;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    z-index: 0;
}

.vacancies .bg-image {
    background-image: url(../../images/banners/company-min.jpg);
}

.education .bg-image {
    background-image: url(../../images/banners/man-min.jpg);
}

.inform-item.map-clients {
    position: relative;
    justify-content: flex-start;
    .bg-image {
        background-image: url(../../images/base_content/projects/new-projects.png);
        background-position: 80% center;
    }

    .box {
        text-align: left;
    }
}

.box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 15px;
    z-index: 200;
    text-align: right;
}

.vacancies .h2 {
    max-width: 175px;
    margin-bottom: 15px;
}

.education .h2 {
    max-width: 230px;
    margin-bottom: 15px;
}

@include media-breakpoint-up(sm) {
    .info-content {
        position: relative;
        overflow: hidden;
        height: 470px;

        .container {
            padding-right: 0;
        }

        &:after {
            content: '';
            width: 48%;
            height: 100%;
            display: block;
            position: absolute;
            right: -10%;
            top: 0;
            bottom: 0;
            transform: skew(-15deg);
            @include gradient-opacity;
            z-index: 1;
        }

        &-projects {
            height: auto;
            .container {
                padding-left: 0;
            }
            &:after {
                display: none;
            }

            &:before {
                display: none;
            }
        }
    }

    .info-container {
        flex-direction: row;
        &-projects {
            flex-wrap: wrap;
        }
    }

    .news-block {
        flex: 0 0 270px;
        padding-top: 30px;
        padding-right: 30px;

        &-projects {
            flex: 1 0 100%;
            max-width: 100%;
            padding-left: 35px;
            padding-right: 35px;

            .new {
                flex: 1 0 50%;
                height: calc(50% - 30px);
            }
        }

        .news-header .h2 {
            font-size: 1.625rem;
        }
    }

    .inform-block {
        position: relative;
        flex: 1 0 auto;

        &:before {
            content: '';
            display: block;
            width: 200%;
            height: 1px;
            background-color: #ebebeb;
            position: absolute;
            left: 0;
            right: 0;
            top: 235px;
            opacity: 0.3;
            z-index: 205;
        }

        &-projects:before {
            display: none;
        }
    }

    .inform-item {
        height: 235px;
        margin: 0;
        padding: 0;
    }

    .news-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 30px;
    }

    .news-list {
        margin-bottom: 8px;

        &-projects {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 32px;
            padding-right: 0;
        }
    }

    .new {
        height: auto;
        max-height: 167px;
        max-height: none;
    }

    .box {
        color: #fff;
        padding-right: 35px;
        align-items: flex-end;
        z-index: 202;

        .btn {
            padding: 9px 25px;
        }
    }

    .news-btn {
        text-align: left;

        .btn {
            width: auto;
        }
    }

    .vacancies .h2 {
        font-size: 1.625rem;
        max-width: 140px;
    }

    .education .h2 {
        font-size: 1.625rem;
        max-width: 150px;
    }

    .map-clients .h2 {
        font-size: 1.625rem;
    }

    .vacancies .bg-image {
        background-image: url(../../images/banners/company-big.jpg);
    }

    .education .bg-image {
        background-image: url(../../images/banners/man-big.jpg);
    }

    .inform-item.map-clients {
        position: relative;
        justify-content: center;
        .bg-image {
            background-image: url(../../images/base_content/projects/projects-news.jpg);
            background-position: 80% center;
        }
        .box {
            align-items: flex-start;
        }
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            @include gradient-opacity;
            z-index: 1;
        }
    }
}

@include media-breakpoint-up(md) {
    .info-container-projects {
        flex-wrap: nowrap;
    }

    .info-content {
        height: 580px;

        &-projects {
            height: 100%;
        }

        &:after {
            width: 52%;
        }
    }

    .inform-item {
        height: 290px;
        justify-content: flex-end;

        .h2 {
            max-width: 330px;
            font-size: 2.125rem;
            margin-bottom: 40px;
            text-align: right;
        }

        .box .btn {
            padding: 9px 42px;
        }

        &.map-clients {
            .box .btn {
                padding: 9px 15px;
            }
        }
    }

    .news-block {
        flex: 0 0 305px;

        &-projects {
            flex: 0 0 66%;
            max-width: 66%;
        }

        .news-header .h2 {
            font-size: 2.125rem;
            margin-bottom: 18px;
        }

        .new {
            max-height: 202px;
        }
    }

    .inform-block {
        flex: 1 0 auto;

        &:before {
            top: 290px;
        }

        &-projects {
            flex: 0 0 34%;
            max-width: 34%;
        }
    }

    .news-list {
        margin-bottom: 25px;
    }

    .new {
        padding: 15px 0;

        .new-head {
            font-size: 0.875rem;
            margin-bottom: 5px;
        }

        a.new-text {
            font-size: 1rem;
            line-height: 1.5;
        }

        .new-footer {
            font-size: 0.875rem;
        }

        &+& {
            margin-top: 0;
        }
    }

    .inform-item.map-clients {
        height: 100%;
        .box {
            align-items: flex-end;
        }
    }
}

@include media-breakpoint-up(lg) {
    .news-block {
        flex: 0 0 365px;
        &-projects {
            flex: 0 0 66%;
            max-width: 66%;
        }
    }

    .info-content {
        &:after {
            width: 47%;
        }
    }

    .inform-item.map-clients {
        .box .btn {
            padding: 9px 42px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .info-content {
        &:after {
            width: 43%;
        }
    }

    .inform-block {
        position: relative;

        &:after {
            content: '';
            width: 1500px;
            height: 100%;
            position: absolute;
            right: -1200px;
            bottom: 0;
            top: 0;
            background: linear-gradient(90deg, rgba(27, 145, 212, 0.0035364487591911242) 0%, rgba(27, 145, 212, 1) 20%, rgba(21, 80, 158, 0.7) 100%);
            z-index: 201;
        }
    }

    .news-block {
        flex: 0 0 410px;
        &-projects {
            flex: 0 0 66%;
            max-width: 66%;
        }
    }
}

@media (min-width: 1350px) {
    .info-content {
        &:after {
            width: 48%;
        }
    }
}

@media (min-width: 1920px) {
    .info-content {
        &:after {
            width: 55%;
        }
    }
}


// products-page

.products-page {
    .products-content {
        padding-top: 0;
    }
}

.title-banner,
.banner-right {
    &.animated {
        animation-duration: 1.5s;
        animation-delay: 0.3s;
    }
}

@include media-breakpoint-up(sm) {
    .products-page {
        .products-content {
            padding-top: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    .products-page {
        .products-content {
            padding-top: 25px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .products-page {
        .products-content {
            padding-bottom: 0;
        }
    }
}

// banner

.section-banner {
    padding: 0;
}

.banner-content {
    position: relative;
    width: 100%;
}

.banner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #ebebeb;
    overflow: hidden;

    &--height-auto {
        &.banner:after {
            display: none;
        }

        .banner-right {
            height: auto;
            background: linear-gradient(0, rgba(27, 145, 212, 0.7) 0%, rgba(21, 80, 158, 0.7) 100%);
            margin: 0 -15px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 2;
    }

    &:after {
        content: '';
        width: 100%;
        height: 75px;
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        @include gradient-opacity;
        z-index: 3;
    }

    &--client {
        &:before {
            display: none;
        }

        picture {
            display: none;
        }
    }

    &--connection:after {
        height: 100%;
    }
}

.banner-bg {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.banner-action {
    .btn {
        width: 100%;
        max-width: 320px;
    }
}

.banner-action-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .btn {
        flex: 1;
        width: auto;

        &:last-child {
            margin-right: 5px;
        }
    }
}

.banner-inner {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;
}

.banner-left {
    padding: 15px 0;
    overflow: hidden;

    &--connection {
        display: flex;
        align-items: center;
    }
}

.banner-right {
    height: 75px;
    text-align: center;
    padding: 20px 0;
    z-index: 10;
}

.banner-info {
    display: flex;
    justify-content: space-between;
    color: #fff;
    line-height: 1.3;

    &__title {
        font-size: 1rem;
        font-weight: 700;
        text-align: left;
        flex: 0 0 150px;
        max-width: 150px;
        margin-right: 15px;
    }

    &__text {
        text-align: right;
        max-width: 290px;
        font-size: 1rem;
    }
}

.title-banner {
    color: $white;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 300;

    &--client {
        color: $primary;
    }

    .big {
        font-size: 1.875rem;
        font-weight: 300;
        margin-bottom: 0;
    }

    .str-small {
        display: inline-block;
        margin-top: 10px;
    }
}

.banner-img {
    display: block;
    max-width: 250px;
    height: 50px;
    object-fit: contain;
    object-position: left;
    margin-top: 40px;
    margin-bottom: 15px;
    &-mobile {
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    .banner {
        background-image: none;
        min-height: 370px;

        &--height-auto {
            min-height: auto;

            &.banner:after {
                display: block;
            }

            .banner-right {
                background: none;
                margin: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &:before {
            display: none;
        }

        &:after {
            width: 52%;
            height: 100%;
            top: 0;
            left: auto;
            right: -13%;
            transform: skew(-15deg);
        }

        &--client {
            picture {
                display: initial;
            }
        }

        &--connection {
            min-height: auto;
            padding: 25px 0;

            &:after {
                width: 35%;
            }
        }
    }

    .banner-bg {
        width: 66%;
        transform: skew(-15deg) translateX(28%);

        img {
            transform: skew(15deg) translateX(-19%);
        }
    }

    .banner-left {
        width: 45%;
    }

    .banner-action {
        .btn {
            width: auto;
        }
    }

    .banner-action-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .btn {
            width: 192px;
            padding: 9px 20px;

            &:first-child {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .banner-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 270px;

        &__title {
            font-size: 1.5rem;
            flex: auto;
            text-align: right;
            max-width: none;
            margin-bottom: 10px;
            margin-right: 0;
        }

        &__text {
            max-width: none;
        }
    }

    .banner-inner {
        flex-direction: row;
        padding: 20px 0 55px;
    }

    .slide-left {
        max-width: 52%;
        padding: 0;

        .line-break {
            display: block;
        }
    }

    .banner-right {
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;
        padding: 0;
        padding-right: 10px;
        text-align: right;

        .info-banner {
            font-weight: 600;
            font-size: 2.125rem;
            line-height: 1.3;
            color: $white;
            text-align: right;
            margin-bottom: 25px;
        }
    }

    .title-banner {
        color: $primary;
        font-size: 2.25rem;

        .big {
            font-size: 2.75rem;
        }

        .line-break {
            display: block;
        }

        .str-small {
            margin-top: 30px;
        }
    }

    .banner-img {
        height: auto;
        margin-bottom: 0;
        &-mobile {
            display: block;
        }
        &-logo {
            width: auto;
            height: 65px;
            margin-bottom: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    .banner {
        background-image: none;

        &:after {
            width: 42%;
        }

        &--connection:after {
            width: 35%;
        }
    }

    .banner-bg {
        width: 54%;
    }

    .slide-left .line-break {
        display: inline-block;
    }

    .banner-left {
        width: 50%;
    }

    .banner-right .info-banner {
        font-size: 2.5rem;
        font-weight: 400;
    }

    .title-banner {
        font-size: 2.5rem;

        .big {
            font-size: 3.125rem;
        }

        .line-break {
            display: inline;
        }
    }

    .banner-action-group .btn {
        width: 240px;
    }

    .banner-img {
        max-width: 300px;
        max-height: 85px;
        margin-top: 60px;
        &-logo {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .banner {
        background-image: none;

        &:after {
            width: 45%;
        }

        &--connection:after {
            width: 30%;
        }
    }

    .banner-bg {
        width: 65%;

        &--connection {
            width: 50%;
        }
    }

    .banner-inner {
        padding: 25px 0 55px;
    }

    .banner-left {
        max-width: 42%;
    }

    .banner-info {
        max-width: 290px;
    }

    // .title-banner {
    //     .big {
    //         font-size: 3.125rem;
    //     }

    //     .str-small {
    //         font-size: 2.5rem;
    //     }
    // }
}

@include media-breakpoint-up(xl) {
    .banner {
        min-height: 440px;

        &--height-auto {
            min-height: auto;
        }

        &--connection {
            min-height: auto;
        }
    }

    .banner-bg {
        &--connection {
            width: 45%;
        }
    }
}

// section-clients-desc
.section-clients-desc {
    padding-bottom: 0;

    .section-content {
        margin-bottom: 0;
    }

    .section-title {
        padding: 0 15px;
        margin-bottom: 0;
    }
}

.clients-slider {
    height: 105px;
    padding: 0;
    opacity: 0;
    margin-bottom: 15px;

    &:before,
    &:after {
        content: '';
        width: 10px;
        height: 50px;
        background-color: #fff;
        position: absolute;
        top: 30px;
        z-index: 4;
    }

    &:before {
        left: -15px;
    }

    &:after {
        right: -15px;
    }

    .slick-track {
        display: flex;
    }

    .slick-list {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px !important;
        margin-left: -15px;
        margin-right: -15px;
    }

    .clients-slide {
        position: relative;
        height: 70px;
        margin: 0 5px;

        &-image {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 15px;

            .box {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &:after {
                    content: '';
                    display: block;
                    border: 8px solid transparent;
                    border-left: 8px solid $white;
                    border-top: 8px solid $white;
                    position: absolute;
                    bottom: -12px;
                    left: 10px;
                    z-index: 5;
                    opacity: 0;
                    transition: opacity 0.15s linear;
                }
            }

            img {
                flex-shrink: 0;
                opacity: 0;
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 70px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                border: 1px solid #d4d4d4;
                transform: skew(-15deg);
                opacity: 0;
                transition: opacity 0.15s linear;
            }

            &:after {
                content: '';
                display: block;
                border: 7px solid transparent;
                border-left: 7px solid #1a89cd;
                border-top: 7px solid #1a89cd;
                position: absolute;
                bottom: -14px;
                left: 9px;
                z-index: 1;
                opacity: 0;
                transition: opacity 0.15s linear;
            }

            &:focus {
                outline: none;
            }
        }
    }
}

.clients-slider.slick-initialized {
    opacity: 1;
    .clients-slide-image picture,
    .clients-slide-image img {
        opacity: 1;
        max-height: 50px;
        object-fit: contain;
    }
    .clients-slide-image:before {
        opacity: 1;
    }
}

.clients-slider .slick-slide.slick-active:hover {
    cursor: pointer;
    .clients-slide-image:before {
        border-image: linear-gradient(39deg, rgba(27, 145, 212, 0.4) 0%, rgba(21, 80, 158, 0.4) 100%);
        border-image-slice: 1;
        z-index: 5;
        opacity: 1;
        transition: border-width .1s linear;
    }
}

.clients-slider .slick-slide.slick-current,
.clients-slider .slick-slide.slick-current:hover {
    .clients-slide-image:after {
        opacity: 1;
    }

    .clients-slide-image:before {
        border-color: #30519a;
        border-image: linear-gradient(39deg, rgba(27, 145, 212, 1) 0%, rgba(21, 80, 158, 1) 100%);
        border-image-slice: 1;
        opacity: 1;
    }

    .clients-slide-image .box:after {
        opacity: 1;
    }
}

@media (min-width: 485px) {
    .clients-slider {
        margin-left: 0;
        margin-right: 0;

        .slick-list {
            width: 90%;
            margin: 0 35px;
            padding-left: 5px !important;
            padding-right: 15px !important;
        }

        .slick-slide .clients-slide-image picture,
        .slick-slide .clients-slide-image img {
            opacity: 1;
            max-height: 50px;
            object-fit: contain;
        }

        &.slick-initialized {
            .clients-slide-image:before {
                opacity: 0;
            }

            .slick-active .clients-slide-image:before {
                opacity: 1;
            }
        }

        &:before,
        &:after {
            display: none;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-clients-desc .clients-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .clients-slider {
        height: 150px;
        width: 100%;
        justify-content: center;
        margin-bottom: 0;

        .slick-list {
            width: 95%;
            padding: 0 25px !important;
            margin: 0 30px;
        }

        .clients-slide {
            height: 100px;
            margin: 0 10px;

            &-image {
                &:before {
                    height: 100px;
                    border-width: 1px;
                    // transition: border-width 0.2s ease-in;
                }

                &:after {
                    border: 9px solid transparent;
                    border-left: 9px solid #1a89cd;
                    border-top: 9px solid #1a89cd;
                    bottom: -16px;
                }

                img {
                    opacity: 1;
                }
            }

            .box:after {
                bottom: -10px;
                left: 11px;
            }

            img {
                padding: 5px;
            }

        }
    }

    .clients-slider .slick-slide {
        &.slick-current .clients-slide-image:before {
            border-width: 3px;
        }
        &.slick-active:hover .clients-slide-image:before {
            border-width: 3px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-clients-desc {
        padding-top: 55px;

        .section-title {
            margin-bottom: 0;
        }
    }

    .clients-slider {
        height: 190px;
        margin: 5px 0 0;
        padding: 0;

        .slick-list {
            margin: 0 30px;
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }

        .clients-slide {
            margin: 0 15px;
            height: 135px;

            &-image {
                &:before {
                    height: 135px;
                }

                &:after {
                    border: 12px solid transparent;
                    border-left: 12px solid #1a89cd;
                    border-top: 12px solid #1a89cd;
                    bottom: -22px;
                }
            }

            .box:after {
                border: 10px solid transparent;
                border-left: 10px solid $white;
                border-top: 10px solid $white;
                bottom: -15px;
                left: 12px;
            }
        }
    }

    .clients-slider .slick-slide .clients-slide-image img,
    .clients-slider .slick-slide .clients-slide-image picture {
        max-height: 100px;
        object-fit: contain;
    }
}

// clients-description

.clients-description {
    position: relative;
    background-color: $bg-color;
    width: 100%;

    .desc-slide {
        position: relative;
        overflow: hidden;
        background-color: $bg-color;

        &:not(:first-child) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
        }
    }

    .desc-content {
        display: none;
    }

    .desc-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 35px 0 45px;
        opacity: 0;

        &.active {
            opacity: 1;
        }

        &.animated {
            animation-duration: 1.5s;
            animation-delay: 0.3s;

            &.active {
                animation-delay: 0s;
            }
        }
    }

    .desc-image {
        height: 50px;
        flex-shrink: 0;
        margin-bottom: 25px;

        img {
            width: auto;
            max-width: 250px;
            height: 100%;
            object-fit: contain;
        }
    }

    .name-client {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .text {
        padding-right: 30px;
    }

    .read-more {
        color: $blue;
        font-size: 0.875rem;
    }

    .desc-bg {
        display: none;
    }
}

.clients-description.slick-initialized {
    .desc-slide {
        position: relative;
    }
}

@include media-breakpoint-up(sm) {
    .clients-description {
        height: 365px;

        .desc-slide {
            height: 365px;

            &:after {
                content: '';
                width: 26%;
                height: 100%;
                display: block;
                position: absolute;
                right: -10%;
                top: 0;
                bottom: 0;
                transform: skew(-15deg);
                background: linear-gradient(0, rgba(27, 145, 212, 0.7) 0%, rgba(21, 80, 158, 0.7) 100%);
                z-index: 1;
            }
        }

        .desc-content {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 66%;
            height: 100%;
            transform: skew(-15deg) translateX(10%);
            overflow: hidden;
            opacity: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: skew(15deg) translateX(-10%);
            }

            &.active {
                opacity: 1;
            }

            &.animated {
                animation-duration: 2s;
                animation-delay: 0.3s;

                &.active {
                    animation-delay: 0s;
                }
            }
        }

        .desc-container {
            flex: 0 0 40%;
            max-width: 40%;
            padding: 50px 0 45px;
        }

        .desc-image {
            margin-bottom: 18px;
        }

        .name-client {
            margin-bottom: 15px;
        }

        .text {
            margin-bottom: 25px;
        }
    }

}

@include media-breakpoint-up(xl) {
    .clients-description {
        height: 410px;

        .desc-slide {
            height: 410px;

            &:after {
                width: 23%;
            }
        }

        .desc-content {
            width: 70%;
        }

        .desc-container {
            flex: 0 0 35%;
            max-width: 35%;
            padding-top: 65px;

            a {
                font-size: 1rem;
            }
        }

        .desc-image {
            height: 58px;
            margin-bottom: 50px;
        }

        .name-client {
            font-size: 1.125rem;
        }

    }
}

@media (min-width: 1920px) {
    .clients-description {
        .desc-content {
            width: 62%;
        }
    }
}


// section-advantages

.section-advantages {
    position: relative;
    padding: 25px 0;
    background-image: url(../../images/products/advantages.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: $white;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba($color: $primary, $alpha: 0.7);
        z-index: 1;
    }

    .advantages {
        position: relative;
        z-index: 2;
    }
}

.advant-list {
    .col-left .advant:last-child {
        margin-bottom: 0;
    }

    .advant {
        display: flex;
        margin-bottom: 13px;

        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            font-size: 1rem;
            font-weight: 700;
            margin-right: 15px;
            @include gradient;
        }

        &-text {
            display: flex;
            flex-direction: column;
            font-size: 0.875rem;
        }

        &-title {
            font-weight: 700;
            margin-bottom: 3px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-advantages {
        padding: 55px 0;

        .advantages {
            @include flex-center;
        }

        .section-title {
            @include flex-center;
            flex-shrink: 0;
            width: 285px;
            height: 285px;
            background-color: transparent;
            border-radius: 50%;
            border: 3px dotted $white;
            margin-bottom: 0;

            .h2 {
                font-size: 1.5rem;
                padding: 50px 25px;
                margin-bottom: 0;
            }
        }

        .section-content {
            position: absolute;
            margin-bottom: 0;
        }

        .advant-list {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            width: 285px;
            height: 285px;
            border-radius: 50%;

            .advant {
                .number {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    font-size: 1.375rem;
                }

                &-text {
                    position: absolute;
                }

                &-title {
                    font-size: 1rem;
                    line-height: 1.2;
                }

                &-subtitle {
                    font-size: 0.8125rem;
                    line-height: 1.3;
                }
            }

            .col-left {
                display: flex;
                flex-direction: column-reverse;

                .advant {
                    width: 100%;
                    position: absolute;
                    flex-direction: row-reverse;

                    .advant-text {
                        text-align: right;
                    }

                    &:nth-child(1) {
                        bottom: 2%;
                        left: -70%;

                        .advant-text {
                            top: 0px;
                            left: -7px;
                            width: 78%;
                        }
                    }

                    &:nth-child(2) {
                        bottom: 39%;
                        left: -86%;

                        .advant-text {
                            top: -17px;
                            left: 13px;
                            width: 72%;
                        }
                    }

                    &:nth-child(3) {
                        top: 5%;
                        left: -70%;

                        .advant-text {
                            top: -40px;
                            left: -9px;
                            width: 78%;
                        }
                    }
                }
            }

            .col-right {
                .advant {
                    width: 100%;
                    position: absolute;

                    &:nth-child(1) {
                        top: 5%;
                        right: -76%;

                        .advant-text {
                            top: -39px;
                            right: 16px;
                            width: 75%;
                        }
                    }

                    &:nth-child(2) {
                        bottom: 39%;
                        right: -93%;

                        .advant-text {
                            top: -16px;
                            right: 59px;
                            width: 60%;
                        }
                    }

                    &:nth-child(3) {
                        bottom: 2%;
                        right: -76%;

                        .advant-text {
                            top: 1px;
                            right: 35px;
                            width: 69%;
                        }

                        .advant-title {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-advantages {
        padding: 35px 0 30px;

        .section-title {
            width: 370px;
            height: 370px;
            margin-bottom: 0;
            padding: 0 40px;

            .h2 {
                font-size: 2.125rem;
                line-height: 1.3;
            }
        }

        .advant-list {
            width: 370px;
            height: 370px;

            .advant {
                .number {
                    width: 55px;
                    height: 55px;
                }

                &-title {
                    font-size: 1.125rem;
                }

                &-subtitle {
                    font-size: 1rem;
                }
            }

            .col-left {
                .advant {
                    &:nth-child(1) {
                        bottom: 5%;
                        left: -74%;

                        .advant-text {
                            top: 0px;
                            left: -65px;
                            width: 93%;
                        }
                    }

                    &:nth-child(2) {
                        bottom: 39%;
                        left: -87%;

                        .advant-text {
                            top: -2px;
                            left: -41px;
                            width: 85%;
                        }
                    }

                    &:nth-child(3) {
                        top: 9%;
                        left: -74%;

                        .advant-text {
                            left: -75px;
                            top: -15px;
                            width: 95%;
                        }
                    }
                }
            }

            .col-right {
                .advant {
                    &:nth-child(1) {
                        top: 9%;
                        right: -77%;

                        .advant-text {
                            top: -15px;
                            right: 12px;
                        }
                    }

                    &:nth-child(2) {
                        bottom: 39%;
                        right: -91%;

                        .advant-text {
                            top: -2px;
                            right: -5px;
                            width: 80%;
                        }
                    }

                    &:nth-child(3) {
                        bottom: 5%;
                        right: -77%;

                        .advant-text {
                            top: 0;
                            right: -40px;
                            width: 90%;
                        }

                        .advant-title {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// section-request

.section-request {
    padding: 25px 0 20px;
    background-color: $bg-color;

    .section {
        padding: 0;
    }

    .section-title {
        margin-bottom: 10px;
    }

    .section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;

        .text {
            margin-bottom: 0;
        }
    }
}

.form-callback {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-callback-call {
    width: 100%;
    max-width: 340px;
    text-align: center;
    margin-top: 20px;

    .form-group {
        width: 100%;
        margin-bottom: 8px;

        &+.form-button {
            margin-top: 20px;
        }
    }

    .input-label {
        position: absolute;
        top: 17px;
        left: 15px;
        color: $input-text;
        font-size: 0.875rem;
        line-height: 1;
        pointer-events: none;
        transition: all 0.2s linear;
        margin-bottom: 0;
    }

    .label-wrap {
        position: relative;
        width: 100%;
        margin-bottom: 0;

        &.filled {
            .input-label {
                display: block;
                background-color: transparent;
                top: 9px;
                color: #07509e;
                font-size: 0.625rem;
                padding-left: 0;
                pointer-events: auto;
            }

            .form-control {
                padding-top: 17px;
            }
        }
    }

    .form-control {
        height: 47px;
        font-size: 1rem;
        padding-left: 15px;

        &::placeholder {
            color: $input-text;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .form-control-textarea {
        height: 130px;
        resize: none;
    }

    .form-button .btn {
        width: 100%;
    }
}

.form-group.filled select.form-control-select {
    color: $primary;
}

.submit-success {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sign-success {
    position: relative;
    padding-left: 80px;
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 60px;
        height: 60px;
        background-image: url(../../images/svg/check.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
    }
}

// select2
.select2 {
    width: 100% !important;
}

.select2-container {
    .select2-selection--single {
        height: 35px;

        .select2-selection__rendered {
            padding-left: 15px;
            padding-right: 25px;
        }
    }
}

.select2-container--default {
    .select2-selection--single {
        border: 1px solid #cdd5e3;
        outline: none;

        .select2-selection__rendered {
            line-height: 32px;
            font-size: 0.875rem;
        }

        .select2-selection__placeholder {
            font-size: 0.875rem;
            color: #b1bed4;
        }

        .select2-results__option--selected {
            background-color: #cdd6e9;
        }

        .select2-selection__arrow {
            width: 33px;
            height: 33px;

            b {
                width: 10px;
                height: 10px;
                border: none;
                background-image: url(../../images/vacancy/arrow-down-gray.svg);
                background-repeat: no-repeat;
                background-position: 92% center;
                background-size: 10px;
                margin-top: -5px;
            }
        }
    }

    .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: #ebebeb;
        color: $primary;
    }
}

.select2-dropdown {
    box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.15);
    border: none;
    border-radius: 0;
}

.select2-results__option {
    font-size: 1rem;
    padding: 8px 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $primary;
}

.country-select {
    .select2.select2-container.select2-container--default {
        max-width: none !important;
    }

    .select2-container .select2-selection--single {
        height: 48px;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 45px;
        font-size: 0.875rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        width: 45px;
        height: 45px;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder,
    .select2-results__option {
        font-size: 0.875rem;
    }
}

@include media-breakpoint-up(sm) {
    .select2 {
        max-width: 420px;
    }

    .select2.select2-container.select2-container--default {
        max-width: 420px !important;
        width: 100% !important;
    }
}

@include media-breakpoint-up(md) {
    .select2-container--default .select2-selection--single {

        .select2-selection__placeholder,
        .select2-selection__rendered {
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-up(xl) {
    .country-select {

        .select2-container--default .select2-selection--single .select2-selection__rendered,
        .select2-container--default .select2-selection--single .select2-selection__placeholder,
        .select2-results__option {
            font-size: 1rem;
        }
    }
}

// intl-tel-input
.iti {
    width: 100%;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=tel] {
    padding-left: 60px;
    height: 47px;
    color: $primary;
}

.iti__selected-flag {
    height: 47px;
    width: 55px;
    padding-left: 15px;
    outline: none;
}

.iti__country.iti__highlight {
    background-color: #ebebeb;
}

.iti__country-list {
    box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.3);
    border: none;
}

.iti__flag {
    background-image: url(../../images/flag/flags.png);
}

.iti-mobile .iti--container {
    max-width: 340px;
    bottom: 30px;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    right: auto;
    position: fixed;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        background-image: url(../../images/flag/flags@2x.png);
    }
}

// parsley.js
.parsley-error {
    border-color: #b90e0e;

    .custom-control-label:before {
        border-color: #b90e0e;
    }

    .form-control {
        color: $input-text-err;
    }
}

.parsley-custom-error-message {
    font-size: 13px;
    color: #b90e0e;
    text-align: left;
}


@include media-breakpoint-up(sm) {
    .section-request {
        .section-content .text {
            width: 625px;
            text-align: center;
        }
    }

    .form-callback-call {
        .label-wrap {
            &.filled .input-label {
                font-size: 0.725rem;
            }
        }

        .form-control {
            height: 48px;
        }

        .form-button,
        .form-button .btn {
            width: 100%;
        }
    }
}

@include media-breakpoint-up(md) {
    .form-callback-call {

        .form-control-textarea {
            height: 170px;
        }

        .label-wrap.filled .form-control {
            padding-top: 19px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-request {
        padding-top: 45px;
        padding-bottom: 55px;

        .section-title {
            margin-bottom: 27px;
        }

        .section-content .text {
            width: 645px;
        }
    }

    .form-callback-call {
        .label-wrap {
            &.filled {
                .input-label {
                    top: 10px;
                }

                .form-control {
                    padding-top: 22px;
                }
            }

            &:before {
                top: 15px;
            }
        }

        .input-label {
            font-size: 1rem;
        }
    }
}

// section-treasury
.section-treasury {
    // .btn {
    //     padding: 7px 48px;
    // }
}

@include media-breakpoint-up(sm) {
    .section-treasury {
        padding-top: 35px;
        padding-bottom: 20px;

        &__content {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-treasury {
        .section-title {
            margin-bottom: 25px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-treasury {
        padding-bottom: 35px;

        // .btn {
        //     padding: 11px 48px;
        // }
    }
}

// section-product-advantage

.section-product-advantage {
    .section-content {
        padding-top: 15px;
        margin-bottom: 0;

        + .section-action {
            margin-top: 30px;
        }
    }

    &-indent {
        padding-bottom: 0;
    }
}

.product-advantage {
    display: flex;
    flex-direction: column;
    margin: 0 -15px 20px;

    .animated {
        animation-duration: 1.5s;
    }

    .description {
        flex: 1;
        padding: 0 15px;
    }

    .description-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 7px;

        .title {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 12px;
            line-height: 1.2;
            letter-spacing: -0.2px;
        }

        div {
            animation-name: fadeInUp;
        }
    }

    .image-advantage {
        flex: 1;
        width: 100%;
        height: 160px;
        animation-name: fadeInUp;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &-single {
        margin-bottom: 0;
        .description {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            padding: 35px 15px;

            &-block {
                margin-bottom: 0;
            }

            .text {
                position: relative;
                margin-bottom: 0;
                z-index: 2;
                animation: none;
            }

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(#fff, 0.75);
                z-index: 1;
            }
        }

        .image-advantage  {
            padding: 0 15px;

            iframe {
                height: 250px;
            }
            img {
               display: none;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-product-advantage {
        padding-bottom: 10px;

        &-indent {
            padding-top: 0;
        }
    }

    .product-advantage {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 25px;

        &:nth-child(odd) {
            flex-direction: row;

            .description {
                margin-right: 15px;
            }

            .description-block {
                div {
                    animation-name: fadeInLeft;
                }
            }

            .image-advantage {
                animation-name: fadeInRight;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            .description {
                margin-left: 15px;
            }

            .description-block {
                div {
                    animation-name: fadeInRight;
                }
            }

            .image-advantage {
                animation-name: fadeInLeft;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 50%;
            padding: 0;
        }

        .description-block {
            .title {
                font-size: 1.25rem;
            }
        }

        .image-advantage {
            height: 245px;
            flex-basis: 50%;
        }

        &-single {
            &:nth-child(1) {
                flex-direction: row-reverse;

                .description .text {
                    animation-name: fadeInRight;
                }

                .image-advantage {
                    display: block;
                    animation-name: fadeInLeft;
                }
            }

            .description {
                background: none !important;
                padding: 0;
                margin-left: 0;
                margin-right: 0;
                padding-left: 35px;

                &-block {
                    margin-bottom: 7px;
                }
            }

            .image-advantage  {
                iframe {
                    height: 100%;
                }
                img {
                    display: block;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-product-advantage {
        padding-bottom: 25px;

        .section-content + .section-action {
            margin-top: 45px;
        }

        .section-title {
            margin-bottom: 30px;
        }
    }

    .product-advantage {
        margin-bottom: 15px;

        &:nth-child(odd) {
            .description {
                margin-right: 35px;
            }
        }

        &:nth-child(even) {
            .description {
                margin-left: 35px;
            }
        }

        .description-block {
            .title {
                font-size: 1.5rem;
            }

            .text {
                letter-spacing: -0.2px;
            }
        }

        .image-advantage {
            height: 305px;
        }

        &-single {
            margin-bottom: 30px;
            &:nth-child(odd) .description {
                margin-right: 0;
            }

            .description {
                padding-left: 40px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-product-advantage {
        padding-bottom: 40px;
        &-indent {
            padding-bottom: 45px;
        }
    }

    .product-advantage {
        &:nth-child(odd) {
            .description {
                margin-right: 75px;
            }
        }

        &:nth-child(even) {
            .description {
                margin-left: 75px;
            }
        }

        .description-block {
            .title {
                margin-bottom: 20px;
            }

            .text {
                letter-spacing: 0;
                padding-right: 50px;
            }
        }

        &-single {
            .description {
                &:nth-child(odd) {
                    margin-left: 75px;
                    margin-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

// section-projects

.section-projects {
    padding-bottom: 0;

    .section-content {
        margin-bottom: 0;
    }
}

.projects-slider {
    padding-bottom: 15px;

    .slick-dots {
        flex-direction: row;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);

        li {
            button:before {
                color: #aebbcc;
            }

            &.slick-active button:before {
                color: #499bff;
            }
        }
    }

    &--single {
        .slick-dots {
            display: none;
        }
    }
}

.project {
    display: flex;
    flex-direction: column;

    &-head {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .img-bank {
            width: auto;
            height: 50px;

            img {
                height: 100%;
                width: auto;
                max-width: 280px;
                object-fit: contain;
            }
        }
    }

    &-title {
        color: $blue;
        font-size: 1.125rem;
        line-height: 1.2;
        margin-top: 10px;
        flex: 1;
    }

    &-body {
        display: flex;
        flex-direction: column;

    }

    &-item {
        display: flex;
        margin-bottom: 15px;

        .icon {
            display: block;
            width: 35px;
            height: 30px;
            flex-shrink: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 35px 30px;
            margin-right: 15px;

            &-done {
                background-image: url(../../images/svg/note-case.svg);
            }

            &-time {
                background-image: url(../../images/svg/001-appointment.svg);
            }

            &-result {
                background-image: url(../../images/svg/handshake.svg);
            }
        }
    }

    .block {
        display: flex;
        flex-direction: column;
    }

    &-subtitle {
        font-weight: 700;
        margin-bottom: 5px;
    }

    &-text {
        padding-right: 30px;
        line-height: 1.2;

        &.list {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(sm) {
    .project {
        padding: 0 50px;

        &-head {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
        }

        &-title {
            padding-right: 15px;
            letter-spacing: 0.8px;
            margin-top: 0;
        }

        &-body {
            flex-direction: row;
        }

        &-item {
            flex: 0 0 48%;

            &+& {
                margin-left: 35px;
            }

            .icon {
                width: 48px;
                height: 48px;
                background-size: 48px 48px;
            }
        }

        &-subtitle {
            font-size: 1rem;
            line-height: 1.2;
            margin-bottom: 0;
        }

        &-text {
            padding-right: 0;
            line-height: 1.4;
        }
    }

    .projects-slider {
        .slick-prev, .slick-next {
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev {
            transform: rotate(180deg) translateY(50%);
        }

        &--single {
            .slick-track {
                float: left;
            }

            .project {
                padding-left: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-projects {
        padding-bottom: 25px;
    }

    .projects-slider {
        position: relative;
        padding: 0 35px;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 5px;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            z-index: 5;
        }
        &:before {
            left: 33px;
        }
        &:after {
            right: 33px;
        }

        .project-slide {
            border-right: 1px solid #dedede;
        }

        .project-slide:not(:nth-child(1)),
        .project-slide:not(:nth-child(2)) {
            opacity: 0;
        }

        .slick-track {
            display: flex;
        }

        &.slick-initialized {
            .project-slide {
                opacity: 1;
                height: auto;
            }
        }

        &--client {
            .project-slide {
                border-right: none;
            }

            .project-body {
                flex-direction: row;
            }
        }

        &--single {
            padding: 0;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    .project {
        padding: 0 20px;

        &-head {
            min-height: 96px;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 25px;

            .img-bank {
                flex: 0 0 35%;
                max-width: 35%;
                width: auto;
                height: 100%;

                img {
                    width: 100%;
                    max-height: 95px;
                }
            }
        }

        &-title {
            flex: 0 0 65%;
            max-width: 65%;
            font-size: 1.5rem;
            letter-spacing: -0.2px;
            flex-shrink: 3;
        }

        &-body {
            flex-direction: column;
        }

        &-item {
            margin-bottom: 25px;

            &+& {
                margin-left: 0;
            }
        }

        &-subtitle {
            font-size: 1.125rem;
            margin-bottom: 5px;
        }

        &-text {
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .project-item {
        font-size: 1rem;
    }
}

// section-other-products
.section-other-products {
    .section-content {
        margin-bottom: 0;
    }

    .products-slider {
        position: relative;
        height: 140px;
        display: flex;
        justify-content: space-between;
        padding: 0 22px;

        &:before,
        &:after {
            content: '';
            height: 100%;
            width: 50px;
            background-color: #fff;
            position: absolute;
            top: 0;
            z-index: 5;
            transform: skew(-15deg);
        }

        &:before {
            left: -10px;
        }

        &:after {
            right: -25px;
        }

        .slick-arrow {
            z-index: 6;
        }
    }

    .product-slide {
        transform: skew(-15deg);
        border-left: 1px solid #e8e8e8;
    }

    .product {
        padding: 0 25px;
        min-height: 145px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: none;

        &:hover {
            &:after {
                opacity: 0;
            }
        }

        .product-icon {

            &:before,
            &:after {
                display: none;
            }
        }

        .product-icon--1 {
            background-image: url(../../images/svg/bank.svg);
        }

        .product-icon--2 {
            background-image: url(../../images/svg/post.svg);
        }

        .product-icon--3 {
            background-image: url(../../images/svg/MIS.svg);
        }

        .product-icon--4 {
            background-image: url(../../images/svg/inet-bank.svg);
        }

        .product-icon--5 {
            background-image: url(../../images/svg/Treasury.svg);
        }

        .product-icon--6 {
            background-image: url(../../images/svg/adm.svg);
        }

        &:hover {
            background: #fff;
        }

        &:focus {
            outline: 0;
        }

        .product-icon {
            margin-bottom: 15px;
        }

        .product-box {
            text-align: center;
            margin-left: 25px;
        }

        .product-name {
            min-height: 50px;
            color: $blue;
            font-size: 0.875rem;
        }

        .product-icon,
        .product-box {
            transform: skew(15deg);
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-other-products {
        .product {
            max-width: 100%;
            border-right: none;

            .product-icon {
                width: 70px;
                height: 70px;
            }

            .product-name {
                font-size: 1rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-other-products {
        padding-bottom: 20px;

        &.section-other-products-pb {
            padding-bottom: 70px;
        }

        .products-slider .slick-list {
            width: 100%;
            justify-content: space-between;
        }

        .product {
            padding: 10px;

            .product-icon {
                width: 75px;
                height: 75px;
                margin-right: 25px;
            }

            .product-box {
                padding: 0 0 0 15px;
                margin: 0;
            }

            .product-name {
                font-size: 1.125rem;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-other-products {
        padding-bottom: 50px;
    }
}

// section-banner-quote
.section-banner-quote {
    .banner-content {
        height: auto;
    }

    .banner-inner {
        padding-bottom: 20px;
    }

    .banner {
        position: relative;
        flex-direction: column-reverse;

        &:before {
            display: none;
        }

        .banner-left {
            padding: 35px 0 0;
        }

        .btn {
            position: absolute;
            min-width: 230px;
            bottom: 20px;
            left: 50%;
            padding: 6px 15px;
            transform: translateX(-50%);
            z-index: 5;
        }
    }

    .banner-bg {
        position: relative;
        max-height: 260px;
    }

    .quote-block {
        letter-spacing: -0.2px;
        line-height: 1.2;
    }

    .quote {
        font-style: italic;
        padding-right: 10px;

        &:before,
        &:after {
            color: #d7d7d8;
            font-style: normal;
        }

        &:after {
            right: 5px;
        }
    }

    .quote-author {
        margin: 20px 0 25px 0;
        padding: 0 15px 0 2px;
    }
}

@include media-breakpoint-up(sm) {
    .section-banner-quote {
        padding-bottom: 20px;

        .banner {
            flex-direction: row;

            &:after {
                width: 26%;
            }

            .banner-left {
                padding: 35px 0;
            }
        }

        .banner-bg {
            position: absolute;
            max-height: none;
            transform: skew(-15deg) translateX(19%);

            img {
                transform: skew(15deg) translateX(-19%);
            }
        }

        .section-action {
            margin-top: 30px;
        }

        .quote-block {
            line-height: 1.4;
        }

        .quote {
            padding-right: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-banner-quote {
        .banner:after {
            width: 32%;
        }

        .banner-bg {
            width: 57%;
        }

        .banner-inner {
            padding-top: 35px;
        }

        .banner-left {
            width: 50%;
        }

        .quote-block {
            line-height: 1.45;
        }

        .section-action {
            margin-top: 45px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-banner-quote {
        padding-bottom: 50px;

        .quote-block .link-blue {
            font-size: 1rem;
        }
    }
}

.banner-small {
    min-height: 115px;
    &:after {
        display: none;
    }

    &-with-btns {
        &:after {
            display: block;
        }
    }
}

@include media-breakpoint-up(sm) {
    .banner-small {
        &:after {
            display: block;
            width: 35%;
        }

        &-with-btns {
            .banner-inner {
                padding: 45px 0 55px;
            }

            .banner-left {
                padding: 0;
            }

            &:after {
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .banner-small-with-btns {
        .banner-bg {
            width: 65%;
        }
    }
}

// section-description
.section-description {
    padding-bottom: 5px;

    .section-content {
        margin-bottom: 0;
    }
}

.text-block {
    position: relative;
    background-color: #fff;
    z-index: 2;

    .subtitle {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 12px;
    }
}

.strengths {
    &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        z-index: 1;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-image {
        height: 195px;
        margin-bottom: 15px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-description {
        padding-top: 30px;
    }

    .strengths {
        margin-top: 20px;

        &-item {
            flex-direction: row;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 20px;
            }
        }

        &-image {
            max-width: 300px;
            flex: 0 0 300px;
            margin-bottom: 0;
            margin-right: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    .text-block {
        .subtitle {
            font-size: 1.125rem;
            margin-bottom: 25px;
        }
    }

    .strengths {
        &-image {
            max-width: 400px;
            flex: 0 0 400px;
            margin-right: 30px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-description .text {
        margin-bottom: 20px;
    }

    .strengths {
        margin-top: 35px;

        &-item {
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 25px;
            }
        }
    }
}

// section-moduls
.moduls-list {
    margin: 0 -15px;
}

.modul-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modul-box {
    display: flex;
    align-items: center;

    .product-name {
        margin-bottom: 0;
    }
}

.modul-hide {
    display: none;
    flex-direction: column;
    padding: 15px 0;

    .modul-text {
        padding-bottom: 15px;
    }

    .btn {
        width: 100%;
    }
}

.section-moduls {
    .modul {
        padding: 20px 15px;
        height: 111px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: $primary;
            background-position: 0 0;

            &:after {
                opacity: 0;
            }

            .product-icon-before {
                opacity: 1;
            }

            .product-icon-after {
                opacity: 0;
            }
        }

        &.desc,
        &.desc:hover {
            height: 111px;
            color: $white;
            z-index: 500;

            .modul-inner {
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                padding: 20px 15px;
                z-index: 499;
                @include gradient;
            }

            .modul-hide {
                display: flex;
            }

            .product-more {
                display: none;
            }

            .product-icon-before {
                opacity: 0;
            }

            .product-icon-after {
                opacity: 1;
            }
        }
    }

    .product-more {
        background-color: #ececed;

        &:before,
        &:after {
            background-color: #ececed;
        }
    }
}

@include media-breakpoint-up(sm) {
    .moduls-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin-bottom: 0px;
        }
    }

    .modul-box {
        flex-direction: column;

        .product-name {
            text-align: center;
            padding: 0 10px;
        }
    }

    .modul-hide {
        .btn {
            width: auto;
            padding: 6px 5px;
            margin: 0 12px;
        }
    }

    .section-moduls {
        .section-content {
            padding-top: 25px;
        }

        .modul {
            height: 210px;
            flex: 0 0 33.33%;
            border-bottom: 1px solid #e8e8ec;

            &:hover {
                height: 210px;
                z-index: 500;
                text-decoration: none;
                color: $white;
                background-position: 0 0;

                &:after {
                    opacity: 1;
                }

                .product-icon-before {
                    opacity: 0;
                }

                .product-icon-after {
                    opacity: 1;
                }

                .modul-inner {
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    padding: 20px 15px;
                    z-index: 499;
                    @include gradient;
                }

                .modul-hide {
                    display: flex;
                }

                .product-more {
                    display: none;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .modul-hide .modul-text {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(xl) {
    .modul-hide {
        .btn {
            padding: 6px 30px;
        }
    }

    .section-moduls {
        .section-content {
            padding-top: 45px;
        }

        .modul {
            flex: 0 0 25%;

            &:nth-child(n) {
                border-right: 1px solid #e8e8ec;
            }

            &:nth-child(4n+4) {
                border-right: none;
            }
        }
    }
}

// section-technology
.technology-list {
    display: flex;
    flex-direction: column;
}

.technology {
    display: flex !important;
    flex-direction: column;
    padding: 15px 0;

    &-logo {
        width: auto;
        margin-bottom: 20px;

        img {
            width: auto;
            height: auto;
            max-height: 35px;
            object-fit: contain;
            object-position: center center;
        }
    }

    &-info {
        background-color: #ececed;
        margin: 0 -15px;
        padding: 15px;
    }
}

@include media-breakpoint-up(sm) {
    .technology-list {
        flex-direction: row;
        padding-bottom: 30px;

        .slick-list {
            width: 100%;
        }

        .slick-slide:nth-child(even) .technology {
            flex-direction: column-reverse;

            .technology-info.arrow:before {
                top: auto;
                bottom: -114px;
                border: 85px solid transparent;
                border-top: 30px solid #ececed;
            }
        }

        .slick-dots {
            display: flex;
            flex-direction: row;
            justify-content: center;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            li {
                button:before {
                    color: #aebbcc;
                }

                &.slick-active button:before {
                    color: #499bff;
                }
            }
        }
    }

    .technology {
        &-logo {
            @include flex-center;
            height: 345px;
            margin-bottom: 0;
        }

        &-info {
            position: relative;
            height: 345px;
            margin: 0;
            padding: 25px;

            &.arrow:before {
                content: '';
                position: absolute;
                top: -114px;
                left: 50%;
                transform: translate(-50%);
                width: 0;
                height: 0;
                border: 85px solid transparent;
                border-bottom: 30px solid #ececed;
                z-index: -1;
            }
        }

        &:nth-child(even) {
            flex-direction: column-reverse;

            .technology-info.arrow:before {
                top: auto;
                bottom: -114px;
                border: 85px solid transparent;
                border-top: 30px solid #ececed;
            }
        }

        &.one {
            flex: 1 1 100%;

            .technology-logo {
                height: auto;
                margin-bottom: 30px;

                img {
                    height: 65px;
                }
            }

            .technology-info {
                height: auto;
            }

            .list {
                display: flex;

                li:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }

        &.two {
            flex: 0 0 50%;

            .technology-logo {
                height: 200px;
            }

            .technology-info {
                height: 200px;
            }
        }

        &.three {
            flex: 0 0 33.33%;

            .technology-logo {
                height: 280px;
            }

            .technology-info {
                height: 280px;
            }
        }

        &.four {
            flex: 0 0 25%;
        }
    }
}

@include media-breakpoint-up(md) {
    .technology {
        &.three {
            .technology-logo {
                height: 345px;
            }

            .technology-info {
                height: 345px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .technology {
        &.two {
            .technology-logo {
                height: 165px;
            }

            .technology-info {
                height: 165px;
            }
        }

        &.three {
            .technology-logo {
                height: 240px;
            }

            .technology-info {
                height: 240px;
            }
        }
    }

    .technology-logo {
        height: 300px;

        img {
            max-height: 50px;
        }
    }

    .technology-info {
        height: 300px;
    }
}

@include media-breakpoint-up(xl) {
    .technology.one {
        flex-direction: row;

        .technology-logo {
            flex: 0 0 33.33%;
        }

        .technology-info {
            flex: 0 0 66.66%;
        }
    }

    .technology-logo {
        height: 330px;
    }

    .technology-info {
        height: 330px;
    }
}

// Modal
.modal {
    min-height: 100vh;
    .modal-dialog {
        max-width: 885px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__form {
            max-width: 720px;
        }

        &__success {
            max-width: 635px;

            .modal-title-big {
                text-transform: none;
            }

            .modal-body {
                text-align: center;
            }
        }
    }

    .modal-content {
        border: none;
        border-radius: 0;
        box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.4);

        &__form,
        &__success {
            background-color: $bg-color;
        }
    }

    .modal-header {
        padding: 25px 30px 15px;

        &__success {
           position: relative;
           padding-top: 155px;
           &:before {
               content: '';
               position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 60px;
                height: 60px;
                background-image: url(../../images/svg/check-success.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left center;
           }
        }
    }

    .modal-title {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .modal-title-big {
        font-size: 2.125rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 auto;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 23px;
        height: 23px;
        background-color: transparent;
        border: none;
        font-size: 0;
        outline: none;
        padding: 25px 30px 15px;

        &:before,
        &:after {
            content: '';
            width: 2px;
            height: 25px;
            display: block;
            position: absolute;
            top: 10px;
            bottom: 0;
            background-color: #b2b2b2;
            border-radius: 1px;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }

    .modal-body {
        padding: 15px 30px 40px;

        h6 {
            font-weight: 700;
            font-size: 1.125rem;
            margin-bottom: 15px;
        }
    }
}

.modal-modul {
    .modal-title {
        display: flex;
        align-items: center;
        padding-right: 20px;

        img {
            width: 50px;
            height: auto;
            margin-right: 20px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .modal {
        .modal-header__form {
            padding-left: 55px;
            padding-right: 55px;
        }

        .modal-body__form {
            padding-left: 55px;
            padding-right: 55px;
        }
    }

    .modal-modul {
        .modal-title {
            img {
                width: 90px;
            }
        }
    }
}

// About page

.about-page {

    .progress-title,
    .progress-text {
        display: block;
        width: 100%;
    }

    .progress-text {
        padding: 0;
    }

    .progress-image img {
        display: none;
    }
}

.typed-block {
    margin-top: 5px;
    margin-bottom: 20px;

    .typed-subtitle {
        font-size: 1.125rem;
        font-weight: 700;
        margin-right: 20px;
    }

    span {
        color: #1554a1;
        font-size: 1.375rem;
        font-weight: 300;
        font-style: italic;
    }
    #typed-strings {
        display: none;
    }
}

.typed-cursor {
    color: #1554a1;
    font-weight: 700;
    font-size: 1rem;
    padding: 0 5px;
}

.section-more {
    padding-bottom: 0;

    .section-title {
        padding: 0 15px;
    }

    .section-content {
        margin-bottom: 0 !important;
    }
}

.more {
    overflow: hidden;

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        height: 112px;
        cursor: pointer;

        &:nth-child(odd) {
            position: relative;
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
            transition: background-size 0.3s linear;

            &:hover {
                background-size: 110%;

                .more__title {
                    border-bottom-color: $primary;
                }
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgb(215, 215, 215);
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(215, 215, 215, 0) 100%);
                z-index: 2;
            }

            .more__link {
                color: $primary;
            }

            .more__info {
                margin-top: auto;
            }
        }

        &:nth-child(even) {
            @include gradient;

            &:hover {
                .more__title {
                    border-bottom-color: #fff;
                }

                .more__image img {
                    transform: scale(1.1, 1.1);
                }
            }

            .more__link {
                text-align: right;
                color: #fff;
            }
        }

        &--1 {
            background-image: url(../../images/about/more_1.jpg);
        }

        &--3 {
            background-image: url(../../images/about/more_2.jpg);
        }

        &--5 {
            background-image: url(../../images/about/more_3.jpg);
        }
    }

    &__link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 20px 15px;
    }

    &__image {
        width: 60px;
        height: 60px;
        margin-right: 15px;

        img {
            transition: transform 0.3s linear;
        }
    }

    &__info {
        position: relative;
        z-index: 3;
        font-weight: 700;
    }

    &__title {
        display: inline-block;
        font-size: 1.125rem;
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 0.3s linear;
    }

    &__text {
        font-size: 0.875rem;
        line-height: 1.2;
    }
}

@include media-breakpoint-up(sm) {
    .about-page {
        .progress-item {
            flex: 0 0 25%;
        }

        .section-description {
            padding-top: 40px;

            .section-title {
                padding: 0 65px;
            }
        }
    }

    .typed-block {
        padding-left: 50px;
        margin-top: 25px;
        margin-bottom: 45px;

        .typed-subtitle {
            display: inline;
            font-size: 1.25rem;
        }

        span {
            font-size: 1.5rem;
        }
    }

    .section-more .section-title {
        width: 430px;
        margin: 0 auto 20px;
    }

    .more {
        &__list {
            position: relative;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -10%;
        }

        &__link {
            font-weight: 700;
            flex-direction: column;
            align-items: flex-end;
            padding: 20px 70px 20px 40px;
        }

        &__image {
            width: 70px;
            height: 70px;
            margin-right: 0;
        }

        &__item {
            position: relative;
            height: 205px;
            flex: 0 0 33.33%;
            max-width: 33.33%;
            text-align: right;

            &:nth-child(odd) {
                background-position: 100% center;
                background-size: 200%;

                &:hover {
                    background-size: 210%;
                }
            }

            &--1,
            &--3 {
                .more__link {
                    justify-content: flex-end;
                }
            }

            &--4,
            &--6 {
                .more__link {
                    flex-direction: column-reverse;
                }
            }

            &--1,
            &--4 {
                clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);
            }

            &--2,
            &--5 {
                clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
            }

            &--1 {
                transform: translateX(25%);

                .more__link {
                    padding-right: 16%;
                }
            }

            &--2 {
                transform: translateX(10%);

                .more__link {
                    padding-right: 23%;
                    padding-left: 7%;
                }
            }

            &--3 {
                transform: translateX(-10%);
                clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);

                .more__link {
                    padding-right: 25%;
                }
            }

            &--4 {
                transform: translateX(15%);

                .more__link {
                    padding-left: 20%;
                    padding-right: 25%;
                }

                .more__info {
                    margin-right: -25px;
                }
            }

            &--5 {
                transform: translateX(-5%);

                .more__link {
                    padding-right: 18%;
                }

                .more__info {
                    margin-top: 0 !important;
                }
            }

            &--6 {
                transform: translateX(-24%);
                clip-path: polygon(14% 0%, 100% 0%, 100% 100%, 0% 100%);

                .more__link {
                    padding-right: 10%;
                    padding-left: 10%;
                }
            }
        }

        &__title {
            font-size: 1.25rem;
            margin-bottom: 5px;
        }

        &__text {
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .about-page {
        .section-description .section-title {
            padding: 0;
            margin-bottom: 20px;
        }

        .progress-list {
            margin-bottom: 0;
        }

        .progress-item {
            height: auto;
            min-height: 0;
            max-height: none;
            padding-bottom: 35px;
        }
    }

    .section-more .section-title {
        width: 550px;
        margin-bottom: 35px;
    }

    .more {
        &__link {
            padding-right: 100px;
            padding-left: 16%;
        }

        &__title {
            font-size: 1.5rem;
        }

        &__text {
            font-size: 1.125rem;
        }

        &__item {
            height: 225px;

            &--3 {
                .more__link {
                    padding-left: 14%;
                }
            }

            &--5 {
                .more__link {
                    padding-left: 14%;
                    padding-right: 16%;
                }
            }
        }

        &__image {
            width: 75px;
            height: 75px;
        }
    }

    .typed-block {
        .typed-subtitle {
            font-size: 1.5rem;
        }

        span {
            font-size: 1.875rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .about-page {
        .progress-list {
            height: auto;
            margin-bottom: 35px;

            &:before,
            &:after {
                display: none;
            }
        }

        .progress-item {
            height: auto;
            max-height: none;
            min-height: none;
            padding-bottom: 0;
            border-bottom: none;

            &:not(:last-child) {
                border-right: 2px solid #e8e8ec
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-more .section-title {
        width: 100%;
    }

    .about-page {
        .progress-list {
            margin-left: 0;
        }

        .progress-item {
            align-items: flex-start;
            flex-direction: row;
            padding-top: 0;
            padding-bottom: 5px;

            &:not(:last-child) {
                border-right: none;
            }

            &:nth-child(1) {
                order: 4;
            }

            &:nth-child(2) {
                order: 3;
            }

            &:nth-child(3) {
                order: 2;
            }

            &:nth-child(4) {
                order: 1;
            }
        }

        .progress-image {
            width: 60px;
            height: 60px;
            margin-right: 10px;

            img {
                display: block;
            }
        }

        .progress-title {
            display: block;
            text-align: left;
        }

        .progress-text {
            text-align: left;
        }
    }

    .typed-block {
        padding-left: 100px;

        .typed-subtitle {
            font-size: 1.5rem;
        }

        span {
            font-size: 1.875rem;
        }
    }

    .more {
        &__item {
            height: 310px;

            &--4 {
                .more__link {
                    padding-right: 23%;
                }
            }

            &--5 {
                .more__link {
                    padding-right: 18%;
                }
            }
        }

        &__link {
            padding: 25px 21% 35px 45px;

            // img {
            //     width: 90px;
            // }
        }


        &__info {
            width: 360px;
        }

        &__title {
            font-size: 2.125rem;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        &__text {
            font-size: 1.5rem;
            line-height: 1.3;
        }
    }
}

// map
.section-map {
    padding-bottom: 0;

    .section-title {
        padding: 0 15px;
    }
}

#map {
    width: 100%;
    height: 270px;
}

.map-point-desc {
    opacity: 0;
    transform: translateY(-20px);
    transition: transform 0.3s linear;

    &.opened {
        opacity: 1;
        transform: translateY(0);
    }
}

.gm-style {
    > div:nth-child(2) {
        outline: none;
    }
}

.gm-style-iw.gm-style-iw-c {
    width: auto;
    max-width: 320px !important;
    font-family: 'Open sans', sans-serif;
}

.gm-ui-hover-effect {
    width: 15px !important;
    height: 15px !important;
    top: 15px !important;
    right: 15px !important;
    background-image: url(../../images/svg/close-gray.svg) !important;
    background-size: 15px 15px !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;

    img {
        display: none !important;
    }
}

.infowindow {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 20px 15px;

    .close-point {
        position: absolute;
        top: 20px;
        right: 15px;
        width: 15px;
        height: 15px;
    }

    h3 {
        font-size: 1.125rem;
        margin-right: 25px;
        margin-bottom: 15px;
    }

    div {
        font-size: 1rem;
        font-weight: normal;
    }

    span {
        position: relative;
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 700;
        align-self: flex-end;
        margin: 15px 0 5px;
        padding-right: 25px;

        &:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 1px;
            width: 15px;
            height: 100%;
            background-image: url(../../images/svg/location-pin.svg);
            background-size:  15px;
            background-position:  center center;
            background-repeat: no-repeat;
        }

        &:after {
            content: '';
            position: absolute;
            right: 5px;
            top: 0px;
            width: 15px;
            height: 100%;
            background-image: url(../../images/svg/coords2.png);
            background-size:  15px;
            background-position:  center center;
            background-repeat: no-repeat;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-map {
        padding-top: 25px;
    }

    #map {
        height: 335px;
    }

    .gm-style-iw.gm-style-iw-c {
        max-width: 330px !important;
    }

    .map-point-desc {
        display: none;
    }

    .infowindow {
        box-shadow: none;
        padding: 0;

        .close-point {
            display: none;
        }
    }
}

@include media-breakpoint-up(md) {
    #map {
        height: 490px;
    }
}

//  end map

// history
.section-history .section-content {
    margin-bottom: 0;
}

.history {
    &__list {
        position: relative;
        padding: 25px 0 10px;

        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: -20px;
            left: 22px;
            border: 10px solid transparent;
            border-top: 18px solid $blue;
        }
    }

    &__item {
        position: relative;
        display: flex;
        padding-bottom: 20px;

        &:first-child {
            padding-top: 25px;
        }

        &:last-child {
            padding-bottom: 30px;
        }
    }

    &__line {
        display: block;
        width: 2px;
        height: 100%;
        border-right: 2px dotted $blue;
        position: absolute;
        left: 30px;
        top: 0;
        bottom: 0;
        z-index: -1;
    }

    &__img {
        @include flex-center;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        @include gradient;
        flex-shrink: 0;
        margin-right: 15px;

        img {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }
    }

    &__desc {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
    }

    &__data {
        font-size: 1.875rem;
        line-height: 1;
        color: $blue;
        margin-bottom: 5px;

        &--small {
            font-size: 1.125rem;
        }
    }

    &__info {
        font-size: 0.875rem;
        line-height: 1.3;
    }
}

.continuation {
    display: block;
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: -1px;
    margin-top: 15px;
}

@include media-breakpoint-up(sm) {
    .history {

        &__list {
            &:after {
                left: 26px;
            }
        }

        &__item {
            padding-bottom: 25px;
        }

        &__line {
            left: 35px;
        }

        &__img {
            width: 72px;
            height: 72px;

            img {
                width: 40px;
                height: 40px;
            }
        }

        &__data {
            font-size: 2.5rem;

            &--small {
                font-size: 1.375rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-history {
        padding-bottom: 25px;

        .section-title {
            margin-bottom: 25px;
        }
    }

    .history {
        &__list {

            &:after {
                left: 43px;
            }
        }

        &__item {
            &:first-child {
                padding-top: 55px;
            }
        }

        &__line {
            left: 52px;
        }

        &__img {
            width: 105px;
            height: 105px;

            img {
                width: 60px;
                height: 60px;
            }
        }

        &__data {
            font-size: 3.125rem;

            &--small {
                font-size: 1.875rem;
            }
        }

        &__desc {
            padding-top: 15px;
            font-size: 1rem;
        }
    }

    .continuation {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(xl) {
    .section-history {
        padding-bottom: 10px;
    }

    .history {
        &__list {

            &:after {
                left: 50%;
                transform: translate(-50%);
            }
        }

        &__item {
            padding-bottom: 30px;

            &:first-child {
                .history__img {
                    top: 55px;
                }
            }

            &:last-child {
                padding-bottom: 60px;
            }
        }

        &__line {
            left: 50%;
            transform: translate(-50%);
        }

        &__img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            margin-right: 0;
        }

        &__desc {
            width: 100%;
            min-height: 105px;
            flex-direction: row;
            align-items: center;
            padding-top: 0;
        }

        &__data {
            flex: 1 1 50%;
            text-align: right;
            line-height: 0.5;
            padding-right: 90px;
            padding-top: 12px;
        }

        &__info {
            flex: 1 1 50%;
            padding-left: 90px;
            padding-right: 120px;
            font-size: 1rem;
        }
    }

    .continuation {
        text-align: center;
    }
}

// end history
// end about page

// vacancy
.vacancy-char {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 40px;
    }

    &--margin {
        margin-top: 20px;
    }

    &__image {
        display: none;
    }

    &__desc {
        font-size: 1rem;
        line-height: 1.4;
    }

    &__title {
        margin-bottom: 15px;
    }

    &__name {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.3;
        padding-right: 40px;
    }

    &__text {
        font-size: 1.125rem;
        font-weight: 700;
    }

    &__tel {
        display: block;
        color: $primary;
    }
}

.address {
    &__locality {
        margin-bottom: 7px;
    }

    &__phone {
        display: block;
        color: $primary;
    }
}

// section-contact
.section-contact {
    background-color: $bg-color;
    padding: 40px 0;
}

.vacancy-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: auto;
}

.form-contacts {
    width: 100%;
    max-width: none;
    text-align: left;

    .form-control {
        padding-left: 15px;
    }

    .label-wrap:before {
        display: none;
    }
}

.form-submit {
    text-align: center;

    .btn {
        width: 100%;
        max-width: 320px;
    }
}

.files {
    &__input {
        position: absolute;
        top: -50px;
    }

    &__label {
        font-size: 0.875rem;
    }

    &__link {
        padding-left: 40px;
        margin-bottom: 0;
        background-image: url(../../images/vacancy/clip.svg);
        background-size: 30px;
        background-position: left 2px;
        background-repeat: no-repeat;
        color: $blue;
        font-size: 1rem;
        line-height: 2rem;
        cursor: pointer;

        &.disable {
            color: #b1bed4;
            cursor: default;
        }
    }

    &__hint {
        display: block;
        padding-left: 40px;

        &+.files__list {
            margin-top: 15px;
        }
    }

    &__list {
        position: relative;
        margin-bottom: 5px;
        padding-left: 40px;
        color: $blue;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__added {
        position: relative;
        padding-left: 25px;
        padding-right: 20px;

        &:before {
            content: '';
            width: 5px;
            height: 5px;
            @include gradient;
            position: absolute;
            top: 8px;
            left: 0;
            border-radius: 50%;
        }
    }

    &__delete {
        position: absolute;
        display: block;
        top: 4px;
        right: 0;
        height: 15px;
        width: 15px;
        background-image: url(../../images/svg/close-gray.svg);
        background-position: right center;
        background-size: 10px auto;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.custom-control-label {
    font-size: 0.875rem;
    margin-top: 15px;
    cursor: pointer;
}

.custom-control-input {
    top: 15px;
}

.btn--contacts {
    margin-top: 20px;
}

@include media-breakpoint-up(lg) {
    .custom-control-label {
        font-size: 1rem;
    }

}

// end section-contact

@include media-breakpoint-up(sm) {
    .vacancy {
        &__banner {
            width: 120% !important;
            height: 100% !important;
            object-position: 0 !important;
        }
    }

    .section-contact {
        padding-bottom: 30px;
    }

    .vacancy-char {
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 50px;
        }

        &--mt {
            margin-top: 35px;
        }

        &__title {
            display: flex;
            align-items: center;
            flex: 0 0 37%;
            max-width: 37%;
            margin-right: 30px;
            margin-bottom: 0;
        }

        &__desc {
            flex: 0 0 60%;
            max-width: 60%;
        }

        &__name {
            padding-right: 0;
        }

        &__text {
            margin-top: 6px;
        }

        &__list {
            margin-top: 7px;
        }
    }

    .address {
        &__locality {
            margin-top: 7px;
        }
    }

    .vacancy-contact {
        flex-direction: row;
        padding: 20px 0;

        &__title {
            flex: 0 0 37%;
            max-width: 37%;
            margin-right: 30px;
        }

        &__content {
            flex: 0 0 60%;
            max-width: 60%;
        }
    }

    .form-contacts .form-control-textarea {
        height: 170px;
    }

    .form-submit {
        text-align: left;

        .btn {
            width: auto;
        }
    }

    .files {
        &__label {
            background-position: left 5px;
        }

        &__hint {
            display: inline;
            padding-left: 0;
        }
    }

    .btn--contacts {
        width: auto;
        padding: 11px 60px;
        margin-top: 30px;
    }

    .section-more--vacancy {
        padding-top: 50px;

        .section-title {
            width: 100%;
            margin-bottom: 45px;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-contact {
        padding-top: 15px;
    }

    .vacancy-char {
        margin-bottom: 5px;

        &__title {
            flex: 0 0 41%;
            max-width: 41%;
        }

        &__text {
            margin-top: 15px;
        }

        &__image {
            display: inline-block;
            width: 75px;
            height: 75px;
            margin-right: 30px;

            &--ml {
                margin-left: 0;
            }
        }

        &__desc {
            flex: 0 0 56%;
            max-width: 56%;
            margin-top: 24px;

            &--mt {
                margin-top: 5px;
            }

            &--nmt {
                margin-top: 0;
            }
        }

        &__list--nmt {
            margin-top: 0;
        }
    }

    .vacancy-contact {
        &__title {
            flex: 0 0 41%;
            max-width: 41%;
        }

        &__content {
            flex: 0 0 56%;
            max-width: 56%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .vacancy-char {
        &__title {
            flex: 0 0 48%;
            max-width: 48%;
        }

        &__desc {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .vacancy-contact {
        &__title {
            flex: 0 0 48%;
            max-width: 48%;
        }

        &__content {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .vacancy-char {
        &__desc--mt {
            margin-top: 15px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .vacancy {
        &__banner {
            object-position: 0 !important;
        }
    }

    .vacancy-char__text {
        margin-top: 30px;
    }

    .form-contacts .form-control-select {
        font-size: 1rem;
    }
}

// end vacancy

// career

.features {
    &__content {
        margin-bottom: 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        margin-bottom: 15px;
    }
}

.feature {
    flex: 1 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &__image {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &__desc {
        font-size: 0.875rem;
    }
}

.filters {
    &__list {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }
}

.filter {
    &:not(:last-child) {
        margin-bottom: 7px;
    }

    &>label {
        display: none;
    }
}

.career {
    &__wrap {
        display: flex;
        flex-direction: column;
    }
}

.vac {
    position: relative;
    flex: 1 0 100%;
    border: 1px solid #e1e1e7;
    padding: 35px 30px 10px 30px;
    margin-bottom: 15px;
    transition: border-color 0.3s linear;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 3px solid rgba(21, 80, 158, 1);
        border-image: linear-gradient(39deg, rgba(27, 145, 212, 1) 0%, rgba(21, 80, 158, 1) 100%);
        border-image-slice: 1;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s linear;
    }

    &:hover {
        border-color: transparent;

        &:after {
            opacity: 1;
        }

        .vac__title > a {
            border-bottom-color: transparent;
        }
    }

    &--hot {
        &:before {
            content: '';
            display: block;
            width: 23px;
            height: 23px;
            position: absolute;
            top: 15px;
            right: 20px;
            background-image: url(../../images/career/fire.svg);
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 23px;
        }
    }

    &__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__title {
        position: relative;
        display: inline-block;
        font-size: 1.125rem;
        color: #1554a1;
        margin-bottom: 15px;
        padding-right: 25px;
        cursor: pointer;
        z-index: 2;


        & > a {
            border-bottom: 1px solid rgba($blue, $alpha: 0.4);
            transition: border-bottom-color 0.2s linear;
        }
    }

    &__desc {
        font-size: 0.9375rem;
        margin-bottom: 10px;
    }

    &__place {
        font-size: 0.9375rem;
        &:last-child {
            margin-bottom: 5px;
        }
    }

    &__share {
        font-size: 0.9375rem;
        margin-top: auto;
        align-self: flex-end;
    }
}

.share {
    position: relative;
    display: inline-block;

    &:hover {
        .share__link {
            opacity: 0;
            z-index: 1;
        }

        .share__links {
            opacity: 1;
            z-index: 2;
        }
    }

    &__links {
        position: absolute;
        display: flex;
        align-items: center;
        top: 3px;
        right: 0;
        z-index: 1;
        overflow: hidden;
        opacity: 0;
        transition: opacity .15s ease-in-out;
    }

    &__link {
        position: relative;
        font-size: 1rem;
        line-height: 22px;
        margin-right: 35px;
        cursor: pointer;
        z-index: 2;
        transition: opacity .15s ease-in-out;

        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 2px;
            right: -30px;
            background: url(../../images/career/share.svg) center center / 18px no-repeat;
        }
    }

    &__soc {
        display: block;
        height: 16px;
        width: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 0;

        &+& {
            margin-left: 8px;
        }

        &--fb {
            background-image: url(../../images/career/facebook-blue.svg);
        }

        &--tw {
            background-image: url(../../images/career/twitter-blue.svg);
            background-position: 0 1px;
        }

        &--in {
            background-image: url(../../images/career/linkedin-blue.svg);
        }

        &--g {
            background-image: url(../../images/career/google-blue.svg);
            width: 21px;
            background-position: 0 -1px;
        }

        &--vk {
            background-image: url(../../images/career/vk-blue.svg);
            width: 19px;
        }

        &--copy {
            background-image: url(../../images/career/link-blue.svg);
            width: 15px;
            background-size: 15px 15px;
            background-position: 0 1px;
            cursor: pointer;
        }
    }
}

.training {

    &__content {
        display: flex;
        flex-direction: column;
    }
}

.study {
    &__btn {
        max-width: 360px;
        height: 55px;
        @include flex-center;
    }

    &__get-info {
        margin-bottom: 20px;
    }

    &__android {
        max-width: 360px;
    }
}

.android-btn {
    position: relative;
    padding-left: 70px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -2px;
        width: 70px;
        height: 55px;
        background: rgb(21, 85, 162);
        background: linear-gradient(90deg, rgba(27, 145, 211, 1) 0%, rgb(21, 85, 162) 100%);
        border-radius: 3px 0 0 3px;
    }

    &:after {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        left: 20px;
        display: block;
        background-image: url(../../images/career/android-logo.svg);
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center center;
        z-index: 2;
    }
}

.btn--career {
    padding: 6px 10px;
}

@include media-breakpoint-up(sm) {
    .features {
        &__list {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .feature {
        flex: 0 0 48%;
        max-width: 48%;
        margin-bottom: 18px;

        &:nth-child(odd) {
            margin-right: 15px;
        }

        &__image {
            width: 70px;
            height: 70px;
        }
    }

    .filters {
        &>label {
            display: none;
        }
    }

    .filters__list {
        flex-direction: row;
        justify-content: space-between;
    }

    .filter {
        display: flex;
        align-items: center;
        flex: 0 0 48%;
        max-width: 48%;
        margin-right: 15px;

        .select2.select2-container.select2-container--default {
            width: 37% !important;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &>label {
            display: block;
            margin-right: 10px;
            margin-bottom: 0;
        }

        &>label.filter-label {
            &-product {
                flex-basis: 125px;
                max-width: 125px;
                white-space: normal;
            }
            &-country {
                flex-basis: 93px;
                max-width: 93px;
            }
        }

        &--news {
            flex: 0 0 48%;
            max-width: 48%;
        }

        &--career {
            flex: 1 0 auto;
            max-width: none;
        }

        .select2 {
            flex: 1;
        }
    }

    .filters-list {
        display: flex;

        .select2.select2-container.select2-container--default {
            max-width: none;
        }
    }

    .btn--career {
        padding: 9px 4px;
    }
}

@include media-breakpoint-up(md) {
    .banner--career:after {
        width: 45%;
    }

    .filter {
        flex: 0 0 48%;
        &>label.filter-label {
            &-product {
                flex-basis: 190px;
                max-width: 190px;
                white-space: nowrap;
            }
        }
        &--career {
            flex: 1 0 auto;
            max-width: none;
        }
    }

    .filters-vacancy {
        &__content {
            margin-bottom: 10px;
        }
    }

    .feature {
        flex: 0 0 19%;
        max-width: 19%;
        flex-direction: column;
        text-align: center;
        margin-left: 5px;

        &:nth-child(odd) {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        &__image {
            width: 75px;
            height: 75px;
            margin-right: 0;
            margin-bottom: 15px;
        }

        &__desc {
            font-size: 1rem;
        }
    }

    .career {
        &__wrap {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .vac {
        flex: 0 0 49%;
        margin-bottom: 30px;

        &:nth-child(odd) {
            margin-right: 2%;
        }

        &__desc,
        &__place,
        &__link {
            font-size: 1rem;
        }

        &__place {
            margin-bottom: 5px;
        }
    }

    .training {
        &__content {
            margin-bottom: 10px;
        }

        &__inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 10px;
        }

        &__block {
            flex: 0 0 49%;
            max-width: 49%;

            &+& {
                margin-left: 15px;
            }
        }

        &__video {
            margin-top: 10px;
        }
    }

    .study {
        margin-top: 35px;
    }

    .android-btn {
        padding-left: 80px;
        padding-right: 15px;
    }

    .send-resume {
        padding-bottom: 15px;

        &__text {
            width: 800px !important;
            margin-bottom: 10px !important;
        }

        &__btn {
            padding: 12px 40px !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .filter {
        &--career {
            flex: 1 0 32%;
            max-width: 32%;
        }
        &>label.filter-label {
            &-product {
                flex-basis: 245px;
                max-width: 245px;
            }
            &-country {
                flex-basis: 105px;
                max-width: 105px;
            }
        }
    }

    .filters-vacancy {
        &__btn {
            padding: 12px 90px;
        }
    }

    .vac {
        flex: 0 0 32%;
        column-gap: auto;

        &:nth-child(odd) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: 2%;
        }
    }

    .study {
        &__btn {
            padding: 10px;
        }
    }

    .android-btn {
        padding-left: 80px !important;
        padding-right: 15px !important;
    }
}

@include media-breakpoint-up(xl) {
    .section-features {
        padding-bottom: 5px;
    }

    .features {
        padding-top: 15px;

        &__title {
            margin-bottom: 50px;
        }

        &__content {
            margin-bottom: 20px;
        }
    }

    .feature {
        &__image {
            margin-bottom: 20px;
        }
    }

    .send-resume {
        margin-bottom: 0;
    }

    .btn--career {
        padding: 9px 15px;
    }
}

// end career

// contacts
.contact-mails p {
    font-size: 0.875rem;
}

.contact-points {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.contact-point {
    padding: 15px 0;
    border-bottom: 1px solid #dfdfe5;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-bottom: none;
    }

    &__city {
        margin-bottom: 15px;
    }

    &__header {
        margin-bottom: 0;

        &--adds {
            font-size: 0.875rem;
        }
    }

    &__address {
        background: url(../../images/contacts/placeholder.svg) left 5px / 15px no-repeat;
        padding-left: 30px;
        margin-bottom: 15px;
    }

    &__phone {
        background: url(../../images/contacts/telephone.svg) left 5px / 18px no-repeat;
        padding-left: 30px;
    }

    &__link-tel {
        display: block;
        color: $primary;
    }
}

.offices {
    font-size: 0.875rem;

    &__country {
        border-bottom: 1px solid #dfdfe5;

        &:first-child {
            border-top: 1px solid #dfdfe5;
        }
    }

    &__header {
        position: relative;
        padding: 12px 0;

        &.collapsed {
            .offices__arrow svg {
                fill: $primary;
                transform: scale(1);
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 15px;
        height: auto;

        svg {
            fill: #a5a8ac;
            transform: scale(-1);
            transition: fill 0.2s linear,
                transform 0.2s linear;
        }
    }

    &__name {
        display: inline-block;
        font-size: 1rem;
    }
}

.offices-block {
    margin-bottom: 25px;

    &:first-child {
        margin-top: 10px;
    }

    &__header {
        font-weight: 700;
        margin-bottom: 7px;
    }

    &__content {
        margin-bottom: 10px;
    }

    &__adds {
        display: block;
        color: $primary;
    }
}

@include media-breakpoint-up(sm) {
    .contact-mails p {
        font-size: 0.9375rem;
    }

    .contact-points {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .contact-point {
        display: flex;
        flex-direction: column;
        flex: 0 0 48%;
        border-bottom: none;
        margin-bottom: 15px;

        &:first-child {
            padding-top: 15px;
        }

        &:nth-child(odd) {
            margin-right: 20px;
        }

        &__city {
            flex: 0;
            max-width: 100%;
            min-height: 35px;
        }

        &__info {
            font-size: 1rem;
            flex: 0;
            max-width: 100%;
            border-bottom: none;
        }
    }

    .offices {
        font-size: 0.9375rem;
    }
}

@include media-breakpoint-up(md) {
    .contact-mails p {
        font-size: 1rem;
    }

    .contact-points {
        margin-top: 15px;
    }

    .contact-point {
        flex: 0 0 25%;
        max-width: 25%;

        &:nth-child(odd) {
            margin-right: 0;
        }

        &:nth-child(3n+1),
        &:nth-child(3n+2) {
            margin-right: 20px;
        }
    }

    .offices {
        font-size: 1rem;
    }

    .offices-block {
        display: flex;

        &__header {
            flex: 0 0 39%;
            max-width: 39%;
            margin-right: 15px;
            margin-bottom: 0;
        }

        &__info {
            flex: 0 0 58%;
            max-width: 58%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .contact-points {
        max-width: 80%;
    }

    .contact-point {
        flex: 0 0 30%;
        max-width: 30%;

        &:nth-child(3n+1),
        &:nth-child(3n+2) {
            margin-right: 15px;
        }

        &__header {
            font-size: 1.15rem;
        }
    }
}

@include media-breakpoint-up(xl) {
    .offices-block {
        display: flex;

        &__header {
            flex: 0 0 32%;
            max-width: 32%;
        }

        &__info {
            flex: 0 0 65%;
            max-width: 65%;
        }
    }
}

// end contacts

// client-card
.section-client-card {
    padding: 25px 0;

    .section-content {
        margin-bottom: 0;
    }
}

.client-card {
    &__text {
        margin-bottom: 20px;
    }
}

.card {
    width: 100%;
    height: 100%;
    @include gradient;
    color: #fff;
    padding: 30px 25px;

    &__item {
        position: relative;
        font-size: 1rem;
        padding-left: 25px;

        &:after {
            content: '';
            position: absolute;
            top: 25px;
            left: 0;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #fff;
        }
    }

    &__big {
        font-size: 2.125rem;
        font-weight: 700;
    }
}

@include media-breakpoint-up(md) {
    .section-client-card {
        padding: 45px 0 20px;
    }

    .client-card {
        display: flex;
        justify-content: space-between;

        &__text {
            flex: 44%;
            max-width: 44%;
            margin-right: 20px;
            margin-bottom: 0;
        }

        &__block {
            flex: 50%;
            max-width: 50%;
        }
    }

    .card {
        padding: 40px 30px;
    }
}

@include media-breakpoint-up(xl) {
    .section-client-card {
        padding: 65px 0 25px;
    }

    .card {
        padding: 50px 40px;
    }
}

// end client-card

// section-advance
.section-advance {
    padding-bottom: 0;
}

.advance {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    &-list {
        position: relative;
        display: flex;
        flex-direction: column;
        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
            margin-top: -1px;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #e8e8ec;
        padding: 15px 0 20px;

        &:first-child {
            padding-top: 0;
        }

        &.advance-resume {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex: 1 0 auto;
            max-width: 100%;
            background-image: url(../../images/base_content/history-success/stub-picture.jpg);
            background-size: 130%;
            background-repeat: no-repeat;
            background-position: left top 55%;
            border-right: none !important;
        }
    }

    &-resume {
        &-text {
            max-width: 410px;
            color: $blue;
            text-align: right;
            margin-bottom: 0;
        }
    }

    &-title {
        font-size: 2.875rem;
        font-weight: 700;
        line-height: 1.1;
        color: $blue;

        &-sub {
            font-size: 1.625rem;
        }
    }

    &-subtitle {
        color: $blue;
        font-weight: 700;
        text-align: center;
        margin-bottom: 5px;
        padding: 0 5px;
    }

    &-text {
        line-height: 1.25;
        text-align: center;
    }
}

@include media-breakpoint-up(sm) {
    .advance {
        &-list {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-item {
            flex: 0 0 50%;
            max-width: 50%;
            font-size: 1rem;
            padding: 15px 10px 25px;

            &:nth-child(odd) {
                border-right: 1px solid #e8e8ec;
            }

            &:first-child {
                padding-top: 15px;
            }

            &.advance-resume {
                flex-grow: 0;
                max-width: 50%;
            }
        }

        &-title {
            font-size: 3.5rem;
            &-sub {
                font-size: 2rem;
            }
        }

        &-subtitle {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .advance {
        &-list {
            margin-bottom: 35px;
        }

        &-item {
            flex-basis: 33.33%;
            max-width: 33.33%;
            font-size: 1.125rem;
            padding: 0 5px 25px;
            &.advance-resume {
                flex: 1 0 33.33%;
                max-width: none;
            }

            &:first-child {
                padding-top: 0;
            }

            &:nth-child(odd) {
                border-right: none;
            }

            &:nth-child(3n+2),
            &:nth-child(3n+1) {
                border-right: 1px solid #e8e8ec;
            }

            &:nth-child(n+4) {
                padding-top: 25px;
            }
        }

        &-title {
            font-size: 4.375rem;

            &-sub {
                font-size: 2.5rem;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-advance {
        padding-top: 40px;
    }

    .advance {
        &-item {
            flex-basis: 25%;
            max-width: 25%;
            padding: 10px 20px 35px;
            &:nth-child(n) {
                border-right: 1px solid #e8e8ec;
            }

            &:nth-child(4n) {
                border-right: none;
            }

            &:nth-child(4) {
                padding-top: 10px;
            }

            &:first-child {
                padding-top: 10px;
            }

            &.advance-resume {
                flex: 1 0 25%;
                max-width: 100%;
            }
        }
    }
}
// end section-advance

//section-successes
.section-successes {
    padding-bottom: 0;
    .section-content {
        margin-bottom: 0;
    }
}

.successes {
    margin: 0 -15px;

    &__list {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        margin-bottom: 40px;
    }

    &__item:not(:last-child) {
        margin-bottom: 20px;
    }

    &__resume {
        width: 100%;
        height: auto;
        background: linear-gradient(39deg, #1b91d4 0%, #15509e 100%);
        color: #fff;
        padding: 32px 15px;

        h3 {
            margin-bottom: 0;
        }
    }
}
.success {
    &__title {
        margin-bottom: 5px;
    }

    &__text {
        line-height: 1.25;
    }
}

@include media-breakpoint-up(sm) {
    .section-successes {
        // padding-top: 0;
    }

    .successes {
        margin: 0 -35px;

        &__list {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 35px;
            margin-bottom: 25px;
        }

        &__item {
            flex: 0 0 48%;
            max-width: 50%;
            &:nth-child(odd) {
                margin-right: 15px;
            }
            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }
        }

        &__resume {
            padding: 35px;

            h3 {
                letter-spacing: -0.5px;
            }
        }
    }

    .success {
        &__title {
            margin-bottom: 10px;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-successes {
        padding-top: 25px;
        padding-bottom: 40px;
    }

    .successes {
        margin: 0;

        &__list  {
            padding: 0;
        }

        &__resume {
            padding: 35px;
            h3 {
                font-size: 1.5rem;
            }
        }
    }

    .success {
        &__title {
            font-size: 1.5rem;
        }

        &__text {
            font-size: 1rem;
            line-height: 1.5;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-successes {
        padding-top: 45px;
        padding-bottom: 55px;
    }

    .successes {
        display: flex;
        &__list  {
            flex: 0 0 65%;
            max-width: 66.67%;
            margin-right: 30px;
        }

        &__resume  {
            display: flex;
            align-items: center;
            flex: 0 0 32%;
            max-width: 33.33%;
        }
    }
}
//end section-successes

// news
.section-news {
    padding: 0;

    .section-action {
        text-align: center;
        margin-bottom: 35px;
    }
}

.filters-news {
    margin-top: 30px;
    margin-bottom: 40px;
}

.new-item,
.search-block {
    margin-bottom: 30px;

    &__image {
        position: relative;
        display: block;
        width: 100%;
        height: 265px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .2);
            opacity: 0;
            transition: opacity .3s linear;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    &__content {
        position: relative;
        padding: 15px 0;
    }

    &__data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    &__title {
        font-size: 1.125rem;
        margin-bottom: 10px;
    }

    &__desc {
        margin-bottom: 10px;
        font-size: 1rem;
    }

    &__place {
        font-size: 0.875rem;
    }
}

.new-main {
    &__image {
        &:after {
            opacity: 1;
        }

        &:hover:after {
            opacity: 0;
        }
    }
}

@include media-breakpoint-up(sm) {
    .filters-news {
        &>label {
            display: none;
        }
    }

    .filter-news {
        display: flex;
        align-items: center;
        flex: 1 0 48%;
        max-width: 48%;

        label {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        &:first-child {
            margin-right: 15px;
        }

        .select2.select2-container.select2-container--default {
            max-width: none !important;
        }
    }

    .new-item {
        margin-bottom: 20px;

        &__inner {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .new-main {
        min-height: 550px;

        &__content {
            padding: 0 25px 30px 25px;
        }

        &__inner {
            position: relative;
            justify-content: flex-end;
        }

        &__image {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        a.new-main__tag,
        &__title a,
        &__desc,
        &__place {
            margin-top: 10px;
            color: #fff;
        }

        a.new-main__tag {
            margin-top: 0;
        }

        &__title a,
        a.new-main__tag {
            @include underline-white;
        }

        a.new-main__tag {
            text-decoration: none;
            transition: border-bottom 0.2s linear;

            &:hover {
                border-bottom-color: transparent;
                text-decoration: none;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .filter-news {
        &:first-child label {
            flex-basis: 200px;
        }

        &:last-child label {
            flex-basis: 115px;
        }
    }
}

// end news

// single-news
.section-single-news {
    &__title h1 {
        font-weight: 300;
    }
}

.section-news .new-main__inner .new-main__title a {
    margin-top: 10px;
    color: #fff;
    border-bottom: 1px solid hsla(0,0%,100%,.4);
}

.section-breadcrumbs {
    padding: 0;
}

.date-top {
    margin: 25px 0 30px;
}

.date {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375rem;
    color: #92969d;
}

.news-single {
    &__desc {
        margin-top: 25px;
        font-size: 1.625rem;
        font-weight: 300;
        line-height: 1.875rem;
    }

    &__image {
        text-align: left;
        margin-top: 35px;

        img {
            max-height: 450px;
            height: auto;
            max-width: 100%;
            width: auto;
        }

        &-author {
            color: $gray;
            font-size: 0.875rem;
        }
    }

    &__content {
        margin: 35px 0 15px;
    }

    &__list {
        margin-bottom: 20px;
    }

    &__data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .section-quote {
        margin: 0 -15px 15px;
    }

    ul {
        line-height: 1.3;
        list-style-type: none;
        margin-bottom: 15px;

        li {
            position: relative;
            font-size: 0.875rem;
            letter-spacing: -0.25px;
            padding-left: 20px;
            margin-bottom: 3px;

            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                @include gradient;
            }
        }
    }

    @include media-breakpoint-up(md) {

        ul {
            margin-bottom: 20px;
            line-height: 1.5;

            li {
                font-size: 1rem;
                padding-left: 35px;
                margin-bottom: 0;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-single-news {
        &__title {
            text-align: left;
        }
    }

    .section-breadcrumbs {
        padding-top: 35px;
        padding-bottom: 0;
    }

    .news-single {
        &__content {
            margin-bottom: 20px;
        }

        .section-quote {
            margin: 0 -35px 35px;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-single-news {
        &__content {
            margin-bottom: 0;
        }
    }

    .news-single {
        .section-quote {
            margin-bottom: 45px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .news-single {
        &__data {
            min-height: auto;
        }

        .section-quote {
            margin: 0 -100% 45px;
        }
    }
}

// end single-news

// clients-page
.section-clients-map {
    display: none;
}

.section-text {
    padding-bottom: 0;
}

.result {
    margin: 25px 0 10px;

    &__block {
        margin-bottom: 5px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 5px 0 15px;
    }

    &__company {
        flex: 1 0 50%;
        max-width: 50%;
        margin-bottom: 20px;
    }
}

.company {
    &__image {
        width: auto;
        height: 90px;
    }

    &__image-inner {
        position: relative;
        @include flex-center;
        height: 100%;
        width: 95%;
        transform: skew(-15deg);
        padding: 10px;

        img {
            max-width: 120px;
            max-height: 70px;
            object-fit: contain;
            object-position: center;
            filter: saturate(0);
            transform: skew(15deg);
            transition: all .15s ease-in-out;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        &:before {
            border: 1px solid #dddddd;
        }

        &:after {
            opacity: 0;
            border: 3px solid #30519a;
            border-image: linear-gradient(39deg, rgba(27, 145, 212, 1) 0%, rgba(21, 80, 158, 1) 100%);
            border-image-slice: 1;
            transition: opacity 0.2s linear;
            z-index: 3;
        }

        &:hover {
            cursor: pointer;

            &:after {
                opacity: 1;
            }

            img {
                filter: saturate(1);
            }
        }

        &:focus {
            outline: none;
        }
    }

    &__title {
        font-size: 0.875rem;
        margin: 8px 10px 0 -12px;
        padding-right: 15px;
        text-align: center;
    }
}

.reviews__slider {
    padding-bottom: 30px;

    .slick-dots {
        flex-direction: row;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translateX(-50%);

        li {
            &.slick-active button:before {
                color: #499bff;
            }

            button:before {
                color: #aebbcc;
            }
        }
    }

    .quote:after {
        bottom: 0px;
        right: 10px;
    }

    &.slick-initialized .reviews__slide {
        opacity: 1;
    }
}

.reviews__slide:not(:first-child) {
    opacity: 0;
}

@include media-breakpoint-up(sm) {
    .section-text {
        padding: 25px 0 15px;
    }

    .result {
        &__company {
            flex: 1 0 25%;
            max-width: 25%;
        }
    }

    .company {
        &__image {
            height: 115px;
        }

        &__title {
            margin-left: -15px;
        }
    }

    .reviews__slider {
        padding-left: 35px;
        padding-right: 35px;
    }

    .reviews__slide {
        padding: 0 25px;

        .quote:before {
            left: -30px;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-clients-map {
        display: block;
        position: relative;
    }

    .section-text {
        padding-top: 40px;
    }

    .result {
        &__company {
            flex: 1 0 20%;
            max-width: 20%;
        }
    }

    .company {
        &__image {
            height: 125px;
        }

        &__title {
            font-size: 1rem;
        }
    }

    .clients-map-tips {
        position: absolute;
        width: 250px;
        height: auto;
        color: $white;
        z-index: 2;

        &__list {
            position: relative;
            padding: 30px 0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 1000px;
                height: 100%;
                display: block;
                @include gradient;
                padding: 15px 0;
                transform: skew(-15deg) translateX(-74%);
                z-index: -1;
            }
        }

        &__item {
            margin-bottom: 15px;
        }
    }

    .clients-map-tip {
        display: flex;
        align-items: center;

        &__image {
            margin-right: 25px;

            img {
                width: 40px;
                height: 40px;
            }
        }

        &__desc {
            font-size: 1rem;
            line-height: 1.2;
        }
    }

    .clients-map {
        position: relative;

        &__images {
            width: 100%;

            img {
                width: 100%;
            }
        }

        &__item {
            position: absolute;

            &.rossiya {
                top: 10.8%;
                left: 54.3%;

                .line {
                    display: none;
                }

                .clients-map-point {
                    top: -64px;
                    left: -64px;
                }
            }

            &.belarus {
                top: 25.8%;
                left: 42.9%;

                .line {
                    transform: rotate(23deg);
                    top: 1px;
                    left: -25px;
                }

                .clients-map-point {
                    top: 40px;
                    left: -137px;
                }
            }

            &.gruziya {
                top: 43.3%;
                left: 57%;

                .line {
                    height: 50px;
                    transform: rotate(-24deg);
                    bottom: -2px;
                    left: -4px;
                }

                .clients-map-point {
                    top: -134px;
                    left: -100px;
                }
            }

            &.azerbaydzhan {
                top: 45.5%;
                left: 60.8%;

                .line {
                    transform: rotate(-159deg);
                    bottom: -141px;
                    left: -23px;
                }

                .clients-map-point {
                    top: 5px;
                    left: -117px;
                }
            }

            &.turkmenistan {
                top: 48.3%;
                left: 70.8%;

                .line {
                    transform: rotate(-169deg);
                    bottom: -149px;
                    left: -12px;
                }

                .clients-map-point {
                    top: 133px;
                    left: -115px;
                }
            }

            &.kazahstan {
                top: 33.8%;
                left: 73.7%;

                .line {
                    transform: rotate(-13deg);
                    bottom: 0px;
                    left: -12px;
                }

                .clients-map-point {
                    top: -216px;
                    left: -140px;
                }
            }

            &.tadzhikistan {
                top: 48.5%;
                left: 80.1%;

                .line {
                    transform: rotate(-13deg);
                    bottom: -142px;
                    left: 22px;
                }

                .clients-map-point {
                    top: 112px;
                    left: -25px;
                }
            }

            &.kirgiziya {
                top: 43.3%;
                left: 81.1%;

                .line {
                    transform: rotate(25deg);
                    bottom: 0px;
                    left: 37px;
                }

                .clients-map-point {
                    top: -217px;
                    left: 0;
                }
            }

            &.uzbekistan {
                top: 45.7%;
                left: 74.5%;

                .line {
                    transform: rotate(98deg);
                    bottom: -80px;
                    left: 80px;
                }

                .clients-map-point {
                    top: -51px;
                    left: 68px;
                }
            }

            &.caboverde {
                top: 76.6%;
                left: 4.2%;

                .line {
                    transform: rotate(89deg);
                    bottom: -70px;
                    left: 80px;
                }

                .clients-map-point {
                    top: -70px;
                    left: 81px;
                }
            }

            .round {
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: #ffffff;
            }

            .line {
                position: absolute;
                width: 1px;
                height: 150px;
                background-color: #ffffff;
            }
        }
    }

    .clients-map-point {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 22px 20px 25px 20px;
        background-color: #ffffff;
        border-radius: 50%;
        z-index: 1;

        &--size0 {
            display: none;
        }

        &--size1 {
            width: 112px;
            height: 112px;

            .clients-map-point__product {
                padding-left: 10px;
            }
        }

        &--size2 {
            width: 140px;
            height: 140px;

            .clients-map-point__products-list {
                flex-direction: column;
            }

            .clients-map-point__product {
                padding-left: 10px;
            }
        }

        &--size3 {
            width: 165px;
            height: 165px;
        }

        &--size4 {
            width: 180px;
            height: 180px;
        }

        &--size5 {
            width: 195px;
            height: 195px;
        }

        &--size6 {
            width: 210px;
            height: 210px;
            padding: 20px 35px 30px;
        }

        &__title {
            font-size: 0.875rem;
            font-weight: 700;
            color: $blue;
            text-align: center;
        }

        &__products-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
        }

        &__product {
            width: 60px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            margin-top: 10px;
            cursor: pointer;
        }

        &__image {
            flex: 0 0 35px;
            width: 35px;
            height: 35px;
            margin-right: 5px;
        }

        &__count {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1;
            color: $primary;
        }
    }

    .result {
        margin-top: 35px;
    }

    .reviews__slide:not(:nth-child(1)),
    .reviews__slide:not(:nth-child(2)) {
        opacity: 0;
    }

    .reviews__slider {
        &.slick-initialized .reviews__slide {
            opacity: 1;
        }
        .quote:after {
            bottom: 10px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .clients-map {
        &__item {
            &.rossiya {
                top: 11.6%;
                left: 54.7%;
            }

            &.belarus {
                top: 25%;
                left: 46%;

                .line {
                    transform: rotate(8deg);
                    top: 4px;
                    left: -7px;
                }

                .clients-map-point {
                    top: 36px;
                    left: -108px;
                }
            }

            &.gruziya {
                top: 45.8%;
                left: 62.1%;

                .line {
                    transform: rotate(0deg);
                    bottom: 3px;
                    left: 4px;
                }

                .clients-map-point {
                    top: -157px;
                    left: -92px;
                }
            }

            &.azerbaydzhan {
                top: 48.4%;
                left: 65.6%;

                .line {
                    transform: rotate(-141deg);
                    bottom: -130px;
                    left: -44px;
                }

                .clients-map-point {
                    top: -3px;
                    left: -146px;
                }
            }

            &.turkmenistan {
                top: 51.2%;
                left: 75.6%;

                .line {
                    height: 180px;
                    transform: rotate(-157deg);
                    bottom: -165px;
                    left: -30px;
                }

                .clients-map-point {
                    top: 155px;
                    left: -198px;
                }
            }

            &.kazahstan {
                top: 33.7%;
                left: 73.6%;

                .clients-map-point {
                    top: -242px;
                    left: -117px;
                }
            }

            &.tadzhikistan {
                top: 50.9%;
                left: 84.5%;

                .line {
                    transform: rotate(9deg);
                    bottom: -144px;
                    left: -8px;
                }

                .clients-map-point {
                    top: 90px;
                    left: -118px;
                }
            }

            &.kirgiziya {
                top: 42.8%;
                left: 88.4%;

                .line {
                    transform: rotate(7deg);
                    bottom: 0px;
                    left: 12px;
                }

                .clients-map-point {
                    top: -206px;
                    left: -65px;
                }
            }

            &.caboverde {
                top: 87%;
                left: 3.4%;

                .line {
                    transform: rotate(76deg);
                    bottom: -52px;
                    left: 78px;
                }

                .clients-map-point {
                    top: -100px;
                    left: 84px;
                }
            }

            &.uzbekistan {
                top: 44.6%;
                left: 79.7%;

                .line {
                    transform: rotate(110deg);
                    bottom: -98px;
                    left: 76px;
                }

                .clients-map-point {
                    top: -4px;
                    left: 70px;
                }
            }
        }
    }

    .clients-map-point {
        &--size1 {
            width: 127px;
            height: 127px;
        }

        &--size2 {
            width: 157px;
            height: 157px;
        }

        &--size3 {
            width: 185px;
            height: 185px;
        }

        &--size4 {
            width: 190px;
            height: 190px;
        }

        &--size5 {
            width: 220px;
            height: 220px;
            padding-right: 22px;
            padding-left: 22px;
        }

        &--size6 {
            width: 240px;
            height: 240px;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .clients-map-tips {
        width: 340px;

        &__list {
            &:after {
                width: 1400px;
                transform: skew(-15deg) translateX(-74%);
            }
        }
    }

    .clients-map-tip {
        margin-bottom: 13px;

        &__image {
            margin-right: 30px;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .clients-map {
        &__item {
            &.rossiya {
                top: 12%;
                left: 57.5%;

                .clients-map-point {
                    top: -73px;
                    left: -104px;
                }
            }

            &.belarus {
                top: 18.5%;
                left: 44.2%;

                .line {
                    transform: rotate(0deg);
                    top: 7px;
                    left: 5px;
                }

                .clients-map-point {
                    top: 21px;
                    left: -108px;
                }
            }

            &.gruziya {
                top: 41.7%;
                left: 62.1%;

                .line {
                    transform: rotate(-19deg);
                    bottom: -1px;
                    left: -2px;
                }

                .clients-map-point {
                    top: -163px;
                    left: -120px;
                }
            }

            &.azerbaydzhan {
                top: 44.5%;
                left: 64.7%;

                .clients-map-point {
                    top: 2px;
                    left: -177px;
                }
            }

            &.turkmenistan {
                top: 46.2%;
                left: 73.3%;

                .line {
                    height: 216px;
                    transform: rotate(-157deg);
                    bottom: -207px;
                    left: -39px;
                }

                .clients-map-point {
                    top: 195px;
                    left: -224px;
                }
            }

            &.kazahstan {
                top: 33.4%;
                left: 80%;

                .line {
                    transform: rotate(35deg);
                    bottom: -10px;
                    left: 47px;
                }

                .clients-map-point {
                    top: -240px;
                    left: -5px;
                }
            }

            &.tadzhikistan {
                top: 47.2%;
                left: 83.8%;

                .line {
                    transform: rotate(21deg);
                    bottom: -142px;
                    left: -23px;
                }

                .clients-map-point {
                    top: 111px;
                    left: -164px;
                }
            }

            &.kirgiziya {
                top: 40.7%;
                left: 87.6%;

                .line {
                    transform: rotate(-22deg);
                    bottom: -143px;
                    left: 35px;
                }

                .clients-map-point {
                    top: 18px;
                    left: -48px;
                }
            }

            &.caboverde {
                top: 89%;
                left: 2.8%;

                .clients-map-point {
                    top: -115px;
                    left: 110px;
                }
            }

            &.uzbekistan {
                top: 36.2%;
                left: 72.4%;

                .line {
                    transform: rotate(-17deg);
                    bottom: -2px;
                    left: -16px;
                }

                .clients-map-point {
                    top: -233px;
                    left: -116px;
                }
            }

            .round {
                width: 11px;
                height: 11px;
            }
        }
    }

    .clients-map-point {
        &--size1 {
            width: 135px;
            height: 135px;
        }

        &--size2 {
            width: 165px;
            height: 165px;
        }

        &--size3 {
            width: 200px;
            height: 200px;
        }

        &--size4 {
            width: 215px;
            height: 215px;
        }

        &--size5 {
            width: 235px;
            height: 235px;
            padding-right: 30px;
            padding-left: 30px;
        }

        &--size6 {
            width: 245px;
            height: 245px;
        }

        &__title {
            font-size: 17px;
        }

        &__count {
            font-size: 17px;
        }

        &__image {
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
        }
    }

    .result {
        &__company {
            flex: 1 0 16.66%;
            max-width: 16.66%;
        }
    }
}

// end clients-page

// internet-banking
.section-result {
    &__content {
        margin-bottom: 0;
    }
}

.results-list {
    display: flex;
    flex-direction: column;
}

.results {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    &__icon {
        display: block;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        margin-right: 20px;

        &--speed {
            background-image: url(../../images/inet-bank/01-speed.svg);
        }

        &--income {
            background-image: url(../../images/inet-bank/02-income.svg);
        }

        &--reputation {
            background-image: url(../../images/inet-bank/03-reputation.svg);
        }

        &--process {
            background-image: url(../../images/svg/treasury/001-process.svg);
        }

        &--growth {
            background-image: url(../../images/svg/treasury/002-growth.svg);
        }

        &--comfort {
            background-image: url(../../images/svg/treasury/003-armchair.svg);
        }

        &--ecosystem {
            background-image: url(../../images/svg/post/001-icon-2942621.svg);
        }

        &--scale {
            background-image: url(../../images/svg/post/002-scale.svg);
        }

        &--future {
            background-image: url(../../images/svg/post/003-city.svg);
        }

        &--full {
            background-image: url(../../images/svg/abs/002-management.svg);
        }

        &--optimization {
            background-image: url(../../images/svg/abs/003-analysis.svg);
        }

        &--extension {
            background-image: url(../../images/svg/abs/004-website.svg);
        }
    }

    &__title {
        margin-bottom: 0;
    }
}

.connection {
    display: flex;
    justify-content: center;
    min-height: 195px;
    color: #fff;
}

@include media-breakpoint-up(sm)  {
    .connection {
        justify-content: flex-start;
        color: $primary;
        padding: 20px 0;

        h3 {
            margin-bottom: 0;
        }
    }

    .results__icon {
        width: 70px;
        height: 70px;
    }
}

@include media-breakpoint-up(md)  {
    .results-list {
        flex-direction: row;

        &__item {
            flex: 31%;
            max-width: 31%;

            &+& {
                margin-left: 20px;
            }
        }
    }

    .results {
        margin-bottom: 0;

        &__head {
            min-height: 96px;
        }

        &__icon {
            width: 75px;
            height: 75px;
            margin-right: 25px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .results {
        &__head {
            min-height: 115px;
        }
    }

    .connection {
        margin-left: 85px;
    }
}

@include media-breakpoint-up(xl)  {
    .results {
        &__head {
            min-height: auto;
            margin-bottom: 30px;
        }
    }
}
// end internet-banking

// cards
.section-cards {
    padding-bottom: 15px;
    .section-content {
        margin-bottom: 0;
    }
}
.cards {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        &--stub {
            display: none;
        }
    }

    &__image {
        display: inline-block;
        flex: 0 0 30%;
        max-height: 90px;
        max-width: 130px;
        margin-right: 15px;

        img {
            width: 100%;
            height: 100%;
            max-height: 90px;
            object-fit: contain;
        }
    }

    &__name{
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
    &__content{
        .text{
            margin-top: 10px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .cards {
        &__list {
            flex-direction: row;
            flex-wrap: wrap;
        }
        &__item {
            flex-basis: 50%;
            max-width: 50%;
            padding-right: 15px;
            padding-top: 15px;
        }
    }
}
@include media-breakpoint-up(md) {
    .cards {
        &__item {
            display: flex;
            flex-direction: column;
            flex-basis: 33.33%;
            max-width: 33.33%;
            padding-top: 0;
            &--row {
                flex-direction: row;
                flex-basis: 50%;
                max-width: 50%;
            }
            &--stub {
                display: flex;
                flex-direction: row;
                align-items: center;
                > div:first-child {
                    position: relative;
                    font-weight: 700;
                    flex: 0 0 55%;
                    max-width: 55%;
                    font-size: 1.125rem;
                    line-height: 1.35;
                    margin-bottom: 35px;
                    margin-right: 25px;
                }
                > div:last-child {
                    position: relative;
                    flex: 0 0 130px;
                    max-width: 130px;
                    height: 100%;
                    img {
                        position: absolute;
                        top: -20px;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        &__image {
            height: 75px;
            max-height: none;
            flex: 100%;
            max-width: none;
            margin-right: 0;
            margin-bottom: 15px;
            &--row {
                max-width: 180px;
                max-height: 75px;
                margin-right: 30px;
                margin-bottom: 0;
                img {
                    max-height: 75px;
                }
            }
        }
        &__name {
            font-size: 1.125rem;
        }
    }
}
@include media-breakpoint-up(lg) {
    .cards {
        &__item--stub {
            padding: 0 25px;
        }
        &__image {
            height: 95px;
            max-height: 95px;
            margin-bottom: 25px;
            &--row {
                max-height: 85px;
                margin-bottom: 0;
                img {
                    max-height: 85px;
                }
            }
        }
    }
}
// end cards

// filtered-cards
.filtered-cards {
    margin: 20px 0;
}
@include media-breakpoint-up(md) {
    .filtered-cards {
        margin: 35px 0 30px;
    }
}
@include media-breakpoint-up(xl) {
    .filtered-cards {
        margin: 50px 0 35px;
    }
}
// end filtered-cards

// Footer
.footer {
    position: relative;
    flex-grow: 0;
    font-size: 0.875rem;
    color: #ffffff;
    background-color: $primary;
    line-height: 1.3;

    &-inner {
        padding-bottom: 19px;
    }

    &-row {
        padding-top: 23px;
        padding-bottom: 15px;
    }

    &-header {
        margin-bottom: 10px;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #b4becb;
    }

    &-list {
        margin: 0 0 15px 0;
        padding: 0;
        list-style-type: none;

        li {
            font-size: 0.875rem;
            line-height: 1.5;
            padding-top: 6px;
            padding-bottom: 6px;
        }

        a {
            color: #ffffff;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            transition: border-bottom-color 0.2s linear;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }

    &-delimiter {
        width: 100%;
        height: 3px;
        background-color: #7f7f8a;
    }

    &-phone {
        line-height: 1.5;
    }

    address {
        margin-top: 17px;
    }
}

// Copyrights
.copy-wrap {
    margin-top: 15px;
    margin-bottom: 19px;

    .copy {
        margin-right: 25px;
        margin-bottom: 7px;
    }
}

.rights {
    margin-bottom: 3px;
}

// Socials
.social-wrap {
    margin-bottom: 19px;
}

.social-list {
    overflow: hidden;
}

.social {
    float: left;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #9da5b2;
    background-size: auto 14px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.2s linear;

    &:not(:last-child) {
        margin-right: 9px;
    }

    &:hover {
        background-color: #ffffff;
    }

    &--linkedin {
        background-image: url(../../images/base/linkedin.svg);
    }

    &--facebook {
        background-image: url(../../images/base/facebook.svg);
    }

    &--telegram {
        background-image: url(../../images/base/telegram.svg);
    }

    &--youtube {
        background-image: url(../../images/base/youtube.svg);
        background-size: auto 20px;
    }
}

// Mediatex block
.mt-wrap {
    margin-top: 19px;
}

.mt {
    display: flex;
    flex-direction: row;

    &-image {
        display: flex;
        align-items: center;
        margin-right: 9px;

        img {
            width: 30px;
            height: 30px;
        }
    }

    &-text {
        line-height: 1;
        font-size: 0.75rem;

        span {
            padding-bottom: 4px;
        }

        a {
            letter-spacing: -.2px;
        }
    }
}

.btn-full {
    width: 140px;
    height: 100%;
    border: 1px solid rgba(#fff, 0.4);
    font-weight: 600;
    font-size: 0.75rem;
    padding: 5px 0;
}

@include media-breakpoint-up(sm) {
    .footer {
        font-size: 0.9375rem;

        &:after {
            content: '';
            position: absolute;
            bottom: 115px;
            right: 0;
            left: 0;
            background-color: rgba($color: #fff, $alpha: 0.3);
            width: 100%;
            height: 1px;
        }

        &-row {
            padding-top: 35px;
            padding-bottom: 20px;
        }

        &-header {
            margin-bottom: 23px;
        }

        &-delimiter {
            display: none;
        }

        &-second {
            height: 93px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            font-size: 0.875rem;
        }

        &-list {
            margin-bottom: 12px;

            li {
                padding-bottom: 6px;
            }
        }
    }

    .copy-wrap {
        margin-top: 0;
        margin-bottom: 0;
    }

    .social-wrap {
        margin-bottom: 0;
    }

    .social {
        width: 36px;
        height: 36px;
        background-size: auto 17px;

        &--youtube {
            background-size: auto 23px;
        }
    }

    .mt-wrap {
        width: 25%;
        margin-top: 0;
    }
}

@include media-breakpoint-up(md) {

    .footer-header,
    .footer-list li {
        font-size: 1rem;
    }

    .footer .copy-wrap {
        font-size: 0.9375rem;
    }

    .mt-wrap {
        padding-right: 0;

        .mt-text {
            font-size: 0.875rem;
            letter-spacing: 0.5px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .footer {
        font-size: 1rem;

        &:after {
            bottom: 70px;
        }

        &-inner {
            padding-bottom: 0;
        }

        &-row {
            padding-top: 35px;
        }

        &-header {
            margin-bottom: 20px;
            font-size: 1rem;
        }

        &-list li {
            font-size: 1rem;
        }

        &-second {
            height: 70px;
        }
    }

    .copy-wrap {
        div {
            font-size: 1rem;
            display: inline;
        }
    }

    .privacy-policy {
        display: inline-block;
        padding-top: 5px;
    }

    .mt-wrap .mt-text {
        font-size: 0.9375rem;
    }
}

@include media-breakpoint-up(xl) {
    .footer {
        font-size: 1.125rem;

        &-row {
            padding-bottom: 4px;
        }

        &-list li {
            font-size: 1rem;
        }

        .copy-wrap {
            font-size: 0.875rem;
        }
    }
}

// Up
.up {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    float: right;
    margin-top: -75px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    z-index: 1000;
    transition: opacity .15s ease-in-out;

    button {
        outline: none;
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #c8c8d0;
        border: none;
        transition: background-color .15s ease-in-out;
        opacity: 1;
        visibility: visible;

        &:hover {
            background-color: #07509e;
        }

        &:after {
            position: absolute;
            top: -2px;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(../../images/svg/arrow.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-size: 10px 15px;
            transform: rotate(270deg);
        }
    }
}

@include media-breakpoint-up(sm) {
    .up button {
        width: 55px;
        height: 55px;
        border-radius: 55px;

        &:after {
            background-size: 15px 20px;
        }
    }
}

// section-wysiwyg
.section-wysiwyg {
    p {
        font-size: 0.875rem;
        line-height: 1.3;
        margin-bottom: 15px;
    }

    ul {
        line-height: 1.3;
        list-style-type: none;
        margin-bottom: 15px;

        li {
            position: relative;
            font-size: 0.875rem;
            letter-spacing: -0.25px;
            padding-left: 20px;
            margin-bottom: 3px;

            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                @include gradient;
            }
        }
    }

    @include media-breakpoint-up(md) {
        p {
            font-size: 1rem;
            line-height: 1.5;
        }

        ul {
            margin-bottom: 20px;
            line-height: 1.5;

            li {
                font-size: 1rem;
                padding-left: 35px;
                margin-bottom: 0;
            }
        }
    }
}
// end section-wysiwyg

.section-search {
    padding-bottom: 35px;

    .section-title {
        margin-top: 33px;
        margin-bottom: 19px;
        font-size: 26px;
        line-height: 30px;
        text-align: left;

        span {
            color: $blue;
            font-weight: 700;
        }
    }

    .section-content {
        margin-bottom: 30px;
    }

    .search-block {
        border-top: 1px solid #e8e8ec;
        margin-bottom: 0;

        &:last-child {
            border-bottom: 1px solid #e8e8ec;
        }

        &__content {
            padding: 24px 0 22px;
        }
    }
}

.employee-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        padding: 0 45px 30px;
    }

    &__menu {
        margin-right: 20px;
    }

    &__image {
        width: 300px;
        height: 360px;
        background-color: #ececec;
        margin-bottom: 25px;

        img {
            object-fit: cover;
            object-position: center center;
            height: 100%;
        }

        &--no-photo {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;

            img {
                object-fit: contain;
            }
        }
    }
    &__info {
        text-align: center;
        margin-bottom: 17px;
    }
    &__name {
        margin-bottom: 8px;
    }
    &__post {
        font-size: 1.125rem;
        line-height: 1.2;
    }
    &__connection-block {
        margin-bottom: 20px;
    }
    &__action {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 20px;
        margin-bottom: 15px;
        .btn {
            color: #fff;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 15px;
            padding: 10px 25px;

            .fa {
                font-size: 1.125rem;
            }
        }
    }

    &__subtitle {
        font-size: 1rem;
        margin-bottom: 10px;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-top: 2px solid #ededed;
        text-align: center;
        padding: 15px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65px;
            height: 65px;
            border-radius: 5px;
            background-color: #dcdcdc;
            color: $primary;

            i {
                font-size: 32px;
            }
        }
    }
}

.connection {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
    padding: 0;

    &__item {
        margin-bottom: 5px;
        span {
            display: block;
            color: #afafaf;
            font-size: 0.875rem;
        }
    }

    &__link {
        position: relative;
        margin-left: 40px;
        color: $primary;
        font-size: 1rem;

        &:before {
            content: '';
            width: 20px;
            height: 23px;
            position: absolute;
            top: 0;
            left: -40px;
            background-repeat: no-repeat;
            background-position:center center;
            background-size: auto 18px;
        }

        &--address:before {
            background-image: url(../../images/base_content/svg/002-placeholder.svg);
        }

        &--smartphone:before {
            background-image: url(../../images/base_content/svg/smartphone.svg);
        }

        &--phone:before {
            background-image: url(../../images/base_content/svg/001-phone-call.svg);
        }

        &--email:before {
            background-image: url(../../images/base_content/svg/004-email.svg);
        }

        &--site:before {
            background-image: url(../../images/base_content/svg/globalization.svg);
        }

        &--skype:before {
            background-image: url(../../images/base_content/svg/skype-logo.svg);
        }
    }

    p.connection__link {
        margin-bottom: 10px;
    }
}

.employee-menu {
    display: flex;
    align-items: center;

    &__item {
        position: relative;

        &:hover {
            .employee-menu__submenu {
                display: block;
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }
        }
    }

    &__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        border: none;
        background-color: #fff;
        border-radius: 5px;
        transition: background-color 0.2s ease-in-out;

        &:before {
            content: '';
            height: 15px;
            width: 100%;
            background-color: transparent;
            position: absolute;
            bottom: -15px;
            left: 0;
        }

        svg {
            height: 32px;
        }

        &:hover {
            background-color: #ededed;
        }

        & + .employee-menu__btn {
            margin-left: 8px;
        }
    }

    &__submenu {
        display: none;
        position: absolute;
        top: calc(100% + 15px);
        left: 0;
        min-width: 182px;
        max-width: 250px;
        border-radius: 5px;
        opacity: 0;
        z-index: 3;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        transition: opacity 0.2s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            top: -20px;
            left: 20px;
            border: 10px solid transparent;
            border-bottom: 10px solid #fff;
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            top: -24px;
            left: 18px;
            border: 12px solid transparent;
            border-bottom: 12px solid rgba(237, 237, 237, 0.5);
            z-index: 1;
        }

        li {
            &:first-child a {
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }
            &:last-child a {
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-bottom: none;
            }

            a {
                display: block;
                height: 100%;
                width: 100%;
                padding: 8px 20px;
                background-color: #fff;
                border-bottom: 1px solid #e7e7e7 !important;
                transition: background-color 0.2s ease-in-out;
                overflow: hidden;

                span {
                    display: inline-block;
                    color: $primary;
                    border-bottom: 1px solid transparent;
                    transition: color 0.2s ease-in-out,
                                border-bottom-color 0.2s ease-in-out;
                }

                .fa {
                    color: $primary;
                    transition: color 0.2s ease-in-out;
                }

                &:hover {
                    background-color: #ededed;
                    span {
                        color: $blue;
                        border-bottom-color: $blue;
                    }
                    .fa {
                        color: $blue;
                    }
                }
            }
        }
    }
}
