.slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.slick-list.draggable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slick-slide:focus {
    outline: none;
}

.slick-dots {
    width: auto;
    display: flex;
    flex-direction: column;
    bottom: 50%;
    transform: translateY(50%);
    right: 23px;

    li {
        margin: 0;

        button:before {
            height: 10px;
            width: 10px;
            display: block;
            font-size: 10px;
            color: #fff;
            opacity: 0.8;
        }

        &.slick-active button:before {
            color: #499bff;
        }
    }
}

.slick-arrow {
    width: 25px;
    height: 50px;
    z-index: 5;

    svg {
        fill: #e1e1e1;
    }
    
    &:before {
        display: none;
    }

    &.slick-prev {
        transform: rotate(180deg) translate(0, 50%);
        left: 0;
    } 

    &.slick-next {
        right: 0;
    } 

    &:hover svg {
        fill: #b8cbe3;
    }

    &:focus svg,
    &:active svg {
        fill: $blue;
    }
}

@include media-breakpoint-up(sm) {
    .slick-arrow {
        width: 25px;
        height: 60px;
        background-size: 25px 60px;

        &:hover,
        &:focus,
        &:active {
            width: 25px;
            height: 60px;
            background-size: 25px 60px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .slick-arrow {
        width: 30px;
        height: 60px;
        background-size: 30px 60px;

        &:hover,
        &:focus,
        &:active {
            width: 30px;
            height: 60px;
            background-size: 30px 60px;
        }
    }
}