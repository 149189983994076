.banner-main {
    display: flex;
    flex-direction: row;
    background-color: #ebebeb;

    @media (min-width: 320px) and (max-width: 723px) {
        &::before {
            display: none;
        }
    }

    .slide-inner {
        position: relative;
        padding-right: 8px;

        @media (min-width: 1140px) and (max-width: 1899px) {
            padding: 0;
        }

        @media (min-width: 1140px) {
            padding: 20px 0;
        }

        &:after {
            content: '';
            position: absolute;
            width: 204px;
            height: 225px;
            right: -7px;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url('../../../images/base/banners/phone-slide.png');

            @media (min-width: 724px) and (max-width: 1139px) {
                top: 0;
                right: 17px;
                width: 100%;
                height: 100%;
                background-image: url('../../../images/base/banners/desktop-slide.png');
                background-position: center center;
                background-size: 80% 100%;
            }

            @media (min-width: 1140px) and (max-width: 1899px) {
                top: -54px;
                width: 100%;
                height: 112%;
                background-image: url('../../../images/base/banners/tablet-slide.png');
                background-position: center center;
            }

            @media (min-width: 1900px) {
                width: 100%;
                height: 117%;
                bottom: -9px;
                right: 0;
                background-image: url('../../../images/base/banners/full-desktop.png');
                background-position: center center;
            }
        }
    }

    .slide-left {
        display: flex;
        flex-direction: column;
    }

    &__img {
        @media (min-width: 320px) and (max-width: 723px) {
            width: 16px !important;
            height: 16px !important;
        }

        display: block !important;
        position: static !important;
        width: 36px !important;
        height: 36px !important;
    }

    &__subtitle {
        &.tablet {
            display: none;
            @media (min-width: 724px) and (max-width: 1139px) {
                display: block;
            }
        }

        &.desktop-small {
            display: none;

            @media (min-width: 1140px) and (max-width: 1899px) {
                display: block;
            }
        }

        &.desktop {
            display: none;

            @media (min-width: 1900px) {
                display: block;
            }
        }
    }

    &::after {
        display: none;
    }

    &__title {
        margin-bottom: 30px;

        @media (min-width: 724px) and (max-width: 1139px) {
            width: 100% !important;
            min-width: 66%;
            max-width: none !important;
        }

        @media (min-width: 1140px) and (max-width: 1899px) {
            font-size: 40px;
            width: 100% !important;
            max-width: none !important;
        }

        @media (min-width: 1900px) {
            width: 100% !important;
            max-width: none !important;
        }
    }

    &__small-title {
        display: block;

        @media (min-width: 1900px) {
            display: none;
        }
    }

    &__big-title {
        display: none;

        @media (min-width: 1900px) {
            display: block;
        }
    }

    .title-slide {
        @media (min-width: 724px) and (max-width: 1139px) {
            width: 100%;
            max-width: 65%;
            height: auto;
            padding-top: 0;
        }
    }

    &__title {
        margin-bottom: 8px;
        padding-top: 23px;
        width: 56%;
        font-size: 20px;

        @media (min-width: 724px) {
            margin-bottom: 25px;
            padding-top: 0;
            padding-top: 26px;
            max-width: 60%;
            min-height: 44px;
            font-size: 32px;
        }

        @media (min-width: 1140px) {
            font-size: 40px;
        }

        @media (min-width: 1900px) {
            min-height: 87px;
        }
    }

    .subtitle-slide {
        @media (min-width: 724px) {
            font-size: 18px;
        }

        @media (min-width: 1140px) {
            font-size: 20px;
        }

        @media (min-width: 1900px) {
            font-size: 24px;
        }
    }

    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 11px;
        z-index: 999;
        opacity: 0;

        &.animated {
            animation-duration: 1.5s;
            animation-delay: 0.3s;

            &.active {
                animation-delay: 0s;
            }
        }

        &.active {
            opacity: 1;
        }
    }

    &__element {
        display: flex;
        align-items: center;
        gap: 11px;
    }


    &__social {
        font-size: 16px;

        a {
            color: #1554a1;
            border-bottom: 1px solid rgba(21,84,161,.4);
            transition: color 0.2s linear, border-bottom 0.2s linear;
            cursor: pointer;

            &:hover {
                border-bottom: 1px solid transparent;
                text-decoration: none;
            }
        }
    }

    .slide-right {
        opacity: 1 !important;
        position: absolute;
        bottom: 35px;
        right: 0;
    }
}
