// Reset and dependencies
@import "./base/fonts";
@import "./base/variables";
@import "../../../node_modules/bootstrap/scss/functions";
@import "./vendor/bootstrap/variables";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/custom-forms";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/print";
@import "./vendor/bootstrap/custom";
// Slick carousel
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "./vendor/slick/custom";
// Animate Css
@import "../../../node_modules/animate.css/animate.css";
// Hamburgers
@import "./vendor/hamburgers/variables";
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers";
// Intl-tel-input
@import "./vendor/intl-tel-input/intlTelInput.css";
// Select2
@import "../../../node_modules/select2/dist/css/select2.css";
// Font-awesome
// @import "font-awesome";
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";
// Base
@import "./base/style";

@import './base/helper/base.scss';
@import './base/helper/inline.scss';
@import './base/common/banner.scss';
@import './base/common/training-banner.scss';
@import './base/common/top-menu.scss';
@import './base/common/hamburger.scss';
