body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;
    color: $primary;
    overflow-x: hidden;

    &.ovhidden {
        overflow: hidden;
    }
}

.body-inner {
    flex: 1 0 100%;
}

.container {
    height: auto;
    padding: 0 15px;
}

img {
    width: 100%;
    height: auto;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

[v-cloak] {
    display: none;
}

svg {
    transition: fill 0.3s linear;
}

.product-advantage:nth-child(odd) {
    .description {
        transition: translateX(-100%) !important;
    }
}

.bg-white {
    position: relative;
    background-color: #fff;
    z-index: 5;
}

@keyframes opacity-item-icon {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@include media-breakpoint-up(sm) {
    .container {
        padding: 0 35px;
    }
}

@include media-breakpoint-up(lg) {
    body {
        font-size: 1.125rem;
    }
}
