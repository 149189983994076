// colors
$primary: #424852;
$blue: #1554a1;
$link: #5772ad;
$light-blue: #499bff;
$light-gray: #ebebef;
$gray: #b5bdcb;
$bg-color: #ebebeb;
$input-text: #b1bed4;
$input-text-err: #94a1b8;

// mixin
@mixin gradient {
    background: linear-gradient(39deg, rgba(27,145,212,1) 0%, rgba(21,80,158,1) 100%);
}

@mixin gradient-opacity {
    background: linear-gradient(0, rgba(27,145,212,0.7) 0%, rgba(21,80,158,0.7) 100%);
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin underline-blue {
    border-bottom: 1px solid rgba($blue, $alpha: 0.4);
}

@mixin underline-white {
    border-bottom: 1px solid rgba($white, $alpha: 0.4);
}

@mixin underline-gray {
    border-bottom: 1px solid $gray;
}

@mixin underline-dark {
    border-bottom: 1px solid rgba($primary, $alpha: 0.7);
}