@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans'), local('OpenSans'), url('../../fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans'), local('OpenSans'), url('../../fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans'), local('OpenSans'), url('../../fonts/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans'), local('OpenSans'), url('../../fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans'), local('OpenSans'), url('../../fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Open Sans'), local('OpenSans'), url('../../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Staatliches-Regular';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Staatliches-Regular'), local('Staatliches-Regular'), url('../../fonts/Staatliches-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Akrobat-Black';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Akrobat-Black'), local('Akrobat-Black'), url('../../fonts/Akrobat-Black.ttf') format('truetype');
}