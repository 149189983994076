.training-banner {
    width: 100%;
    height: auto;
    position: relative;
    background-color: #d9e1ed;

    &__block {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        width: 236px;
        height: 100%;

        @media (min-width: 320px) and (max-width: 493px) {
            width: 168px;
        }

        @media (min-width: 723px) and (max-width: 959px) {
            margin-right: 20px;
            width: 370px;
        }

        @media (min-width: 1139px) {
            margin-right: 40px;
        }
    }

    &__title {
        align-self: flex-start;
        color: #424852;
    }

    &__link {
        display: block;
        align-self: flex-start;
        padding: 10px 30px;
        color: #1554a1;
        border: 2px solid #1554a1;
        border-radius: 3px;

        &:hover {
            color: #fff;
            background-color: #1554a1;
        }
    }
}
